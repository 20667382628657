import React from "react";
import ReactApexChart from "react-apexcharts";

const Chart =({ data, searchQuery }) => {

  //Get the sum of all data points for the year
    
    const series = [{
        name: 'series(%)',
        data: [23, 14, 33, 12, 4, 37]
      }],
      options = {
        // colors: ["#085e08", "#FF0000"],
        chart: {
          height: 350,
          type: 'bar',

          zoom: { enabled: false },
          toolbar: {show: false},
          // stroke: { color: 'green'}
        },
        plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
        },
        dataLabels: {
          enabled: true,
          fontSize: "140px",
            style: {
                fontSize: '14px'
            },
          offsetX: 30
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
        //   type: 'datetime',
          categories: ['Conservation NGO', 'Government', 'Lecturing', 'Other', 'Teaching', 'Research (post-MSc, PhD & PostDoc)'],
          title: {
            text: `Percentage graduates`,
            style: {
              fontSize:  '16px',
              fontWeight:  'bold',
              fontFamily:  undefined,
              color:  '#565656'
            },
          }
        },
        yaxis: {
            title: {
              text: `Destination`,
              style: {
                fontSize:  '16px',
                fontWeight:  'bold',
                fontFamily:  undefined,
                color:  '#565656'
              },
            }
          },
      }
  return (
      <div>
          <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
  )

}


export default Chart