import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import styles from './BirdList.module.css'
import { getProfiles } from '../../../actions/profile'
import { getSpecies } from '../../../actions/species';
import { checkStatus } from '../../../utils/utils';
// import Spinner from '../../components/Spinner/Spinner';

import { getSpeciesBySearch } from '../../../actions/species';


const BirdList = () => {

  const dispatch = useDispatch()
  const history = useHistory()
  const [search, setSearch] = useState('');
  const { numberOfPages } = useSelector((state) => state.species);
  // const { total } = useSelector((state) => state.species);
  const { species} = useSelector((state) => state.species);
  const [pageNumber, setPageNumber] = useState()
  const pages = new Array(numberOfPages).fill(0).map((v, i) => i);

  const openPost =(slug) => history.push(`/birds/${slug}`)


  useEffect(() => {
      dispatch(getSpecies(pageNumber));
      dispatch(getProfiles())
      // eslint-disable-next-line
  }, [pageNumber]);

  const gotoPrevious = () => {
    setPageNumber(Math.max(0, pageNumber - 1));
  };

  const gotoNext = () => {
    setPageNumber(Math.min(numberOfPages - 1, pageNumber + 1));
  };
  

  const searchPost = () => {
      dispatch(getSpeciesBySearch({ search }));
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchPost();
    }
  };


      if(!species) return null

      // if(isLoading) {
      //     return <Spinner />
      // }
  

    return (
      <div>
        <div className={styles.coverImg}>
                <img src="https://res.cloudinary.com/wabis/image/upload/v1631874208/species/rbfkzfnjxcqy4nvmspvs.jpg" alt="Nigerian birds" />
              </div>
        <div className={styles.layout}>
            <div className={styles.header}>
              <div className={styles.topic}>
              {/* <div className={styles.number}>
                  <h3>{total}</h3>
                  <p>Total <br /> Species</p>
                </div> */}
                <h1 style={{padding: '30px', textAlign: 'center'}}>Species by species account of Nigerian birds</h1>
                <p style={{color: 'black'}}>Compiled by the A.P. Leventis Ornithological research Institute</p>
                
              </div>
                <div className={styles.searchBar}>
                <input type="text" onKeyDown={handleKeyPress} onChange={(e) => setSearch(e.target.value)} placeholder="Type species" />
                <button onClick={searchPost} >Search</button>
                </div>
                {/* <div>
                    <SearchByYear year={year} setYear={setYear} searchPost={searchPost} />
                </div> */}
            </div>




            <div>
            <div className={styles.featuresContainer} >
            <div className={styles.paragraph}>
                {/* <h1 style={{paddingBottom: '30px'}}>Recent<span style={{color: '#68a374'}}> News</span> </h1> */}
                {/* <hr className={styles.hrLine}/> */}
                {/* <p> 
                Stay informed and up-to-date with the latest news and activities of the A.P. Leventis Ornithological Research Institute. Read our conservation news and find out how we’re working to protect our natural world
                </p> */}
            </div>

            <div className={styles.items}>
                <ul className={styles.autoGrid}>
                    {
                      species?.map((post) => (
                        <li key={post._id} className={styles.listItem}>
                        <img onClick={() => openPost(post.slug)} className={styles.imgThumbnail} src={post.fileUrl} alt={post.title} />
                        <div className={styles.specieInfo}>
                          <div>
                              <h2 style={{cursor: 'pointer'}} onClick={() => openPost(post.slug)}>{post.name}</h2>
                              <p style={{marginTop: '-25px', fontStyle: 'italic', color: 'gray', fontSize: '14px'}}>{post.scientificName}</p>
                          </div>
                          <div className={checkStatus(post.status)}>{post.status}</div>
                        </div>
                    </li>
                      ))
                    }
                </ul>
            </div>

        </div>
            </div>

            <div className={styles.pagination}>
              <IconButton onClick={gotoPrevious}>
                <FirstPageIcon />
              </IconButton>
              {pages.map((pageIndex) => (
                <button className={`${styles.pageNum}` } key={pageIndex} onClick={() => setPageNumber(pageIndex)}>
                  {pageIndex + 1}
                </button>
              ))}
              <IconButton onClick={gotoNext}>
                <LastPageIcon />
              </IconButton>
             
            </div>

        </div>
        </div>
    )
}

export default BirdList
