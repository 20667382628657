import React, { useEffect} from 'react'
import Features from './Features/Features'
import { useDispatch, useSelector } from 'react-redux';
import Hero from './Hero/Hero'
import RecentNews from './RecentNews/RecentNews'
import { getSpecies } from '../../../actions/species'
import Spinner from '../../../components/Spinner/Spinner';

import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'


const BirdPage = () => {


  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
  const browserHistory = createBrowserHistory()
  browserHistory.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search)
  })

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])


  const dispatch = useDispatch()
   const { species } = useSelector((state) => state.species);
   console.log(species)
   
   useEffect(() => {
    dispatch(getSpecies());
     // eslint-disable-next-line 
}, []);


// if(!posts) return null

if(!species) {
    return <Spinner />
}



  return (
    <>
        <Hero species={species} />
        <Features />
        <RecentNews species={species} />
    </>
  )
}

export default BirdPage
