import React from 'react'
import styles from './Leventis.module.css'

const Leventis = () => {
    return (
        <div className={styles.content}>
            <div className={styles.img} >
                {/* <img src="https://i.postimg.cc/W1qGRwy6/aplori-logo-white.png" alt="aplori-logo"/> */}
                <img src="https://i.postimg.cc/bv4gdCsc/aplori-logo.png" alt="aplori-logo"/>
            </div>
            {/* <div>
                <h1>A.P. Leventis Ornithological Research Institute</h1>
                <p>The foremost Ornithological Research and Conservation Training in West Africa.</p>
            </div> */}
        </div>
    )
}

export default Leventis
