import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Features from '../components/Features/Features'
import Hero from '../components/Hero/Hero'
import RecentNews from '../components/RecentNews/RecentNews'
import { getNews } from '../actions/posts'
import Weather from '../components/Weather/Weather'
import CapacityBuilding from '../components/CapacityBuilding/CapacityBuilding'

import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'




const HomePage = () => {

  const dispatch = useDispatch()
  const { posts } = useSelector((state) => state.posts)
  const newsPosts = posts.filter((p) => p.postType === 'news')
  const [ weather, setWeather ] = useState({})



  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
const browserHistory = createBrowserHistory()
browserHistory.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search)
})


  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])


  useEffect(() => {
      dispatch(getNews());
       // eslint-disable-next-line 
  }, [location]);


  useEffect(() => {
    fetch('https://aplori.herokuapp.com/weather')
        .then(response => response.json())
        .then(json => setWeather(json))
        .catch((error) => {
            console.log(error.message)
        })
},[])


  return (
    <>
        <Hero posts={newsPosts} />
        <Weather weather={weather}/>
        <Features />
        <CapacityBuilding />
        <RecentNews posts={newsPosts} />
        
    </>
  )
}

export default HomePage
