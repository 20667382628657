import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import styles from './News.module.css'
import { getNews } from '../../actions/posts'
import moment from 'moment'
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
// import Spinner from '../../components/Spinner/Spinner';

import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));



const News = () => {


  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles();
  const { posts, numberOfPages } = useSelector((state) => state.posts);

 
  const [pageNumber, setPageNumber] = useState()
  const pages = new Array(numberOfPages).fill(0).map((v, i) => i);
 

  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
  const browserHistory = createBrowserHistory()
  browserHistory.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search)
  })

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])


  useEffect(() => {
      dispatch(getNews(pageNumber));
      // eslint-disable-next-line
  }, [pageNumber]);

  const gotoPrevious = () => {
    setPageNumber(Math.max(0, pageNumber - 1));
  };

  const gotoNext = () => {
    setPageNumber(Math.min(numberOfPages - 1, pageNumber + 1));
  };

  const openPost =(slug) => history.push(`/news/${slug}`)
  const openUser =(slug) => history.push(`/member/${slug}`)

      if(!posts) return null
  

    return (
      <div className={styles.featuresContainer} >
            <div className={styles.paragraph}>
                <h1 style={{paddingBottom: '30px'}}>News</h1>
                {/* <hr className={styles.hrLine}/> */}
                <p> 
                Stay informed and up-to-date with the latest news and activities of the A.P. Leventis Ornithological Research Institute. Read our conservation news and find out how we’re working to protect our natural world
                </p>
            </div>

            <div className={styles.items}>
                <ul className={styles.autoGrid}>
                    {
                      posts.map((post) => (
                        <li key={post._id} className={styles.listItem}>
                        <img onClick={() => openPost(post.slug)} className={styles.imgThumbnail} src={post.fileUrl ? post.fileUrl : "https://i.postimg.cc/QMR5fZtQ/Amurum.jpg"} alt={post.title} />
                        <h2 style={{cursor: 'pointer'}} onClick={() => openPost(post.slug)}>{post.title}</h2>
                        <div className={styles.authorAndDate}>
                            <div className={styles.author} style={{cursor: 'pointer'}}  onClick={() => openUser(post.userSlug)} >
                            <Avatar className={classes.small}  alt={post.author} src={post.avatar} />
                            <h3 style={{marginLeft: '5px'}}>{post.author}</h3>
                            </div>
                            <p style={{color: 'gray', fontSize: '10px', lineHeight: '10px'}}>{moment(post.createdAt).fromNow()}</p>
                        </div>
                    </li>
                      ))
                    }
                </ul>
            </div>

            <div className={styles.pagination}>
              <IconButton onClick={gotoPrevious}>
                <FirstPageIcon />
              </IconButton>
              {pages.map((pageIndex) => (
                <button className={`${styles.pageNum}` } key={pageIndex} onClick={() => setPageNumber(pageIndex)}>
                  {pageIndex + 1}
                </button>
              ))}
              <IconButton onClick={gotoNext}>
                <LastPageIcon />
              </IconButton>
              </div>

        </div>
    )
}

export default News
