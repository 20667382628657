/* eslint-disable */
import React, { useState, useEffect} from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

import { useWindowWidth } from '@react-hook/window-size'
import { IconButton } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';


import decode from 'jwt-decode'
import * as actionType from '../../actions/constants';

import Avatar from '@material-ui/core/Avatar';
import CloseIcon from '@material-ui/icons/Close';

import UserCard from '../UserCard/UserCard';
import Leventis from '../Leventis/Leventis';


const Nav = () => {

  const width = useWindowWidth()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const { profiles  } = useSelector((state) => state.profiles);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [openUserCard, setOpenUserCard] = useState(false)
  const closeToggle =() => setOpenUserCard(false)
  const [profile, setProfile] = useState({})


  useEffect(() => {
    for (let i = 0; i < profiles.length; i++) {
      if(profiles[i].email === user?.result?.email) {
        setProfile(profiles[i])
      }
    }
  },[profiles])

  
  const logout = () => {
    dispatch({ type: actionType.LOGOUT });
    setOpenUserCard(false)

    history.push('/auth');

    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem('profile')));
      // eslint-disable-next-line
  }, [location]);



return (
<>
  <nav  className="nav">
  <div className="wrapper">
    <div className="logo"><Link to="/"><Leventis /></Link></div>
    <input type="radio" name="slider" id="menu-btn" />
    <input type="radio" name="slider" id="close-btn" />
    <ul className="nav-links">
      <label htmlFor="close-btn" className="btn close-btn"><CloseIcon /></label>
      <li><Link to="/" onClick={() => {window.location.href="/"}}>Home</Link></li>

      <li>
        <Link to="#" className="desktop-item">About</Link>
        <input type="checkbox" id="showDrop1" />
        <label htmlFor="showDrop1" className="mobile-item">About +</label>
        <ul className="drop-menu">
          <li><Link to="/about" onClick={width < 957 ? () => {window.location.href="/about"} : null} >Overview</Link></li>
          <li><Link to="/founder" onClick={width < 957 ? () => {window.location.href="/founder"} : null} >Our Founder</Link></li>
          <li><Link to="/capacity-building" onClick={width < 957 ? () => {window.location.href="/capacity-building"} : null} >Capacity Building</Link></li>
          <li><a href="https://cssn.aplori.org" target="_blank"> Center for Species Survival</a></li>
          <li><Link to="/facilities" onClick={width < 957 ? () => {window.location.href="/facilities"} : null}>Facilities</Link></li>
          <li><Link to="/library" onClick={width < 957 ? () => {window.location.href="/library"} : null}>Library</Link></li>
        </ul>
      </li>


      <li>
        <Link to="#" className="desktop-item">People</Link>
        <input type="checkbox" id="showDrop2" />
        <label htmlFor="showDrop2" className="mobile-item">People +</label>
        <ul className="drop-menu">
            <li><Link to="/people/board-members" onClick={width < 957 ? () => {window.location.href="/people/board-members"} : null }>Board Members</Link></li>
            <li><Link to="/people/staff" onClick={width < 957 ? () => {window.location.href="/people/staff"} : null }>Staff</Link></li>
            <li><Link to="/people/affiliates" onClick={width < 957 ? () => {window.location.href="/people/affiliates"} : null }>Affiliates</Link></li>
            <li><Link to="/people/students" onClick={width < 957 ? () => {window.location.href="/people/students"} : null }>Students</Link></li>
            <li><Link to="/people/alumni" onClick={width < 957 ? () => {window.location.href="/people/alumni"} : null}>Alumni</Link></li>
        </ul>
      </li>

      <li><Link to="/msc-program" onClick={width < 957 ? () => {window.location.href="/msc-program"} : null} >MSc Program</Link></li>
      <li><Link to="/research" onClick={width < 957 ? () => {window.location.href="/research"} : null} >Research</Link></li>
      <li>
      <Link to="#" className="desktop-item">Publications</Link>
        <input type="checkbox" id="showDrop3" />
        <label htmlFor="showDrop3" className="mobile-item">Publications +</label>
        <ul className="drop-menu">
            <li><Link to="/publications" onClick={width < 957 ? () => {window.location.href="/publications"} : null } >Scientific Publications</Link></li>
            <li><Link to="/theses" onClick={width < 957 ? () => {window.location.href="/theses"} : null} >Dissertations & Theses</Link></li>
            <li><Link to="/documents" onClick={width < 957 ? () => {window.location.href="/documents"} : null} >Document Archives</Link></li>
            {/* <li><Link to="/datazone/birds" onClick={width < 957 ? () => {window.location.href="/datazone/birds"} : null} >Birds of Nigeria</Link></li> */}
            {/* <li><Link to="/proposals" onClick={width < 957 ? () => {window.location.href="/proposals"} : null} >Research Planning</Link></li> */}
        </ul>
      </li>

      {/* <li><Link to="/datazone" onClick={width < 957 ? () => {window.location.href="/datazone"} : null} >DataZone</Link></li> */}

      <li>
      <Link to="#" className="desktop-item">News</Link>
        <input type="checkbox" id="showDrop6" />
        <label htmlFor="showDrop6" className="mobile-item">News +</label>
        <ul className="drop-menu">
          <li><Link to="/news" onClick={width < 957 ? () => {window.location.href="/news"} : null } >News</Link></li>
          <li><Link to="/events" onClick={width < 957 ? () => {window.location.href="/events"} : null } >Events</Link></li>
        </ul>
      </li>


       
       
        <li>
      <Link to="#" className="desktop-item">Store</Link>
        <input type="checkbox" id="showDrop5" />
        <label htmlFor="showDrop5" className="mobile-item">Store +</label>
        <ul className="drop-menu">
            {/* <li><Link to="/shop" onClick={width < 957 ? () => {window.location.href="/shop"} : null } >Shop</Link></li> */}
            <li><Link to="/equipment" onClick={width < 957 ? () => {window.location.href="/equipment"} : null} >Borrow Equipment</Link></li>
        </ul>
      </li>
       
        <li><Link to="/contact" onClick={width < 957 ? () => {window.location.href="/contact"} : null }>Contact</Link></li>

        {user?.result?.role === "admin" && ( 
            <li>
            <Link to="#" className="desktop-item">
              <IconButton>
              <ListAltIcon  style={{color: 'white'}} />
              </IconButton>
            </Link>
              <input type="checkbox" id="showDrop4" />
              <label htmlFor="showDrop4" className="mobile-item">Admin +</label>
              <ul className="drop-menu">
                  <li><Link to="/moderator" onClick={width < 957 ? () => {window.location.href="/moderator"} : null } >Moderate Posts </Link></li>
                  <li><Link to="/database" onClick={width < 957 ? () => {window.location.href="/database"} : null} >Manage Users</Link></li>
                  <li><Link to="/species" onClick={width < 957 ? () => {window.location.href="/species"} : null} >Manage Species</Link></li>
              </ul>
            </li>
        )}

            {user?.result ? ( 
            <li>
                <Avatar style={{marginTop: '14px', cursor: 'pointer'}} onClick={() => setOpenUserCard((prevState) => !prevState)} alt={profile.name} src={profile?.profilePicture ? profile?.profilePicture :"https://i.postimg.cc/W4z4Xtck/avatar.png"} />
            </li>
            ) :
            (
              <li><Link to="/auth" onClick={() =>{window.location.href="/auth"}} style={{backgroundColor: '#25422b'}} >Login</Link></li>
            )}

    </ul>

    {/* <label htmlFor="menu-btn" className="btn menu-btn"><i className="fas fa-bars" /></label> */}
    <label htmlFor="menu-btn" className="btn menu-btn"><i style={{paddingRight: '10px'}} className="fas fa-bars" /></label>
  </div>
</nav>

{openUserCard && (
        <UserCard profile={profile} logout={logout} setOpenUserCard={setOpenUserCard} closeToggle={closeToggle} />
    )}

</>

    )
}

export default Nav
