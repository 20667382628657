import React from 'react'

const MapUploader = ({ postData, setPostData }) => {

  let links = []
    var myWidget = window.cloudinary.createUploadWidget({
        cloudName: 'wabis', 
        uploadPreset: 'species'}, (error, result) => { 
          if (!error && result && result.event === "success") { 
            links = [...links, result.info.secure_url]
            setPostData({...postData, map: links})
            // console.log(links)
          }
        }
      )
      
      const openWidget  =(e) => {
        e.preventDefault()
        myWidget.open()
      }

    const buttonStyle = {
      width: '98%',
      padding: '20px',
      border: 'solid 2px #1976d2',
      cursor: 'pointer',
      backgroundColor: 'white',
      margin: '10px auto',
      fontSize: '20px',
      borderRadius: '5px',
      boxShadow: '2px 2px 2px 006'


    }

    return (
            <button style={buttonStyle} onClick={openWidget}>Upload Maps</button>
    )
}

export default MapUploader
