import styles from './utils.module.css'

export const checkStatus =(status)=> {
    switch(status) {
        case "LC": 
        return styles.green

        case "CR":
        return styles.red

        case "VU":
        return styles.yellow

        case "NT":
        return styles.yellowishGreen

        case "DD":
        return styles.gray

        default:
            return null

    }
}