import React from 'react'
import ReactApexChart from 'react-apexcharts';


class ApexChart extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
      
        series: [{
          name: '',
          type: 'column',
          data: [4, 3, 1, 5, 3, 12, 7, 5, 8, 10, 13, 14, 21, 10, 13, 13, 12, 18, 13, 22]
        }, {
          name: '',
          type: 'area',
          data: [4, 3, 1, 5, 3, 12, 7, 5, 8, 10, 13, 14, 21, 10, 13, 13, 12, 18, 13, 22],
        }, ],
        options: {
          chart: {
            height: 350,
            type: 'line',
            stacked: false,
            zoom: { enabled: false },
            toolbar: {show: false},
            
          },
          stroke: {
            width: [0, 2, 5],
            curve: 'smooth'
          },
          plotOptions: {
            bar: {
              columnWidth: '50%'
            },
      
            
          },
          
          fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
              inverseColors: false,
              shade: 'light',
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100]
            }
          },
          labels: ["2002", "2003","2004","2005","2006","2007","2008","2009","2010","2011","2012","2013","2014",
          "2015","2016","2017","2018","2019","2020","2021" ],
          markers: {
            size: 0
          },
          xaxis: {
            type: 'datetime',
            title: {
                text: ""
            }
          },
          yaxis: {
            title: {
              text: 'Number of published papers',
            },
            min: 0
          },
          tooltip: {
            shared: false,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return y.toFixed(0) + " publications";
                }
                return y;
          
              }
            }
          }
        },
      
      
      };
    }

  

    render() {
      return (
        

  <div id="chart">
<ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
<p style={{fontSize: '16px', lineHeight: '17px', marginTop: '-20px'}}><strong>APLORI Publish papers per annum</strong></p>
</div>


      );
    }
  }


  export default ApexChart
