import React from 'react'
// import { Link } from 'react-router-dom'
import styles from './Features.module.css'

const Features = () => {
    
    return (
       
        <div className={styles.featuresContainer} >
            <div className={styles.paragraph}>
                <h1>
               APLORI's species-by-species account of Nigerian birds
                </h1>
                <hr className={styles.hrLine}/>
                <p style={{marginBottom: '-10px'}}> 
                Nigeria is home to over 900 bird species (residential, intra-african and palearctic migrants) 
                spread across its huge ecological sphere. A brief description of the various bird species is 
                presented alongside a distribution map as revealed by the Nigerian Bird Atlas Project (NiBAP).
                </p>
            </div>

        </div>
    )
}

export default Features
