import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styles from './People.module.css'
import { getProfiles } from '../../actions/profile'
import Spinner from '../../components/Spinner/Spinner';
import Thumbnails from '../../components/Thumbnails/Thumbnails'

import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'


const BoardMembers = () => {

    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
    const browserHistory = createBrowserHistory()
    browserHistory.listen((location, action) => {
      ReactGA.pageview(location.pathname + location.search)
    })
  
    useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])

  const dispatch = useDispatch()
  let boardMembers = []
  const { profiles, isLoading } = useSelector((state) => state.profiles)
  const boardMem = profiles?.filter((a) => a?.memberCategory?.includes('boardMember'))
    boardMembers = [
        boardMem[1], 
        boardMem[8],
        boardMem[4],
        boardMem[2],
        boardMem[6],
        boardMem[5],
        boardMem[10],
        boardMem[3],
        boardMem[12],
        boardMem[9],
        boardMem[0],
        boardMem[11],
        boardMem[7],
    ]

  useEffect(() => {
      dispatch(getProfiles())
      // eslint-disable-next-line
  }, []);


      if(!profiles) return null

      if(isLoading) {
          return <Spinner />
      }
  

    return (
        <div className={styles.layout}>
            <div className={styles.header}>
                <h1 style={{padding: '20px'}}>APLORI Board Members</h1>
                <p>Our Board members comprised of seasoned professionals and national leaders with expertise in a wide array of fields. They are responsible for the long-term success and sustainability of the institute.</p>
                
            </div>
            
            <div className={styles.thumbnails}>
                <Thumbnails profiles={boardMembers}/>
            </div>
        </div>
    )
}

export default BoardMembers
