import React from 'react'
// import { Link } from 'react-router-dom'
import styles from './Weather.module.css'
import { Barometer, Humidity, NoRain, Sunny, Time, Wind } from './icons'
import Spinner from './Spinner/Spinner'

const Weather = ({ weather }) => {

    console.log(weather)

  
    return (
       
        <div className={styles.featuresContainer} >
            <div className={styles.items}>
                <ul className={styles.autoGrid}>


                    <li className={styles.listItem}  style={{backgroundColor: '#3e6947'}}>
                        <div className={styles.svg}>
                           <Sunny />
                        </div>
                        <p style={{color: 'white'}}>{weather.temp_c}°C</p>
                            <p style={{fontSize: '10px', lineHeight: '14px', color: '#EEFFDB'}}> Month High: {((Number(weather?.davis_current_observation?.temp_month_high_f) - 32) * 5/9).toFixed(1)}°C </p>
                            <p style={{fontSize: '10px', lineHeight: '14px', color: '#EEFFDB'}}> Month Low: {((Number(weather?.davis_current_observation?.temp_month_low_f) - 32) * 5/9).toFixed(1)}°C</p>
                            <h2 style={{color: 'white'}}>Temperature</h2>
                    </li>


                    <li className={styles.listItem}>
                        <div className={styles.svg}>
                           <Humidity />
                        </div>
                        <p>{weather.relative_humidity}%</p>
                        <p style={{fontSize: '10px', lineHeight: '14px'}}> Month High: {weather?.davis_current_observation?.relative_humidity_month_high}%</p>
                        <p style={{fontSize: '10px', lineHeight: '14px'}}> Month Low: {weather?.davis_current_observation?.relative_humidity_month_low}%</p>
                            <h2>Humidity</h2>
                    </li>


                    <li className={styles.listItem}>
                        <div className={styles.svg}>
                          <NoRain/>
                        </div>
                       {
                           isNaN(Number(weather?.davis_current_observation?.rain_day_in).toFixed(1)) ? <Spinner /> :
                            <p>{Number(weather?.davis_current_observation?.rain_day_in).toFixed(1)}<span style={{fontSize: '14px'}}>mm</span></p>
                       }
                        <p style={{fontSize: '10px', lineHeight: '14px'}}> Month Average: {(Number(weather?.davis_current_observation?.rain_month_in) * 25.4).toFixed(1) }mm</p>
                        <p style={{fontSize: '10px', lineHeight: '14px'}}></p>
                            <h2>Rain</h2>
                    </li>

                    <li className={styles.listItem}>
                        <div className={styles.svg}>
                           <Wind />
                           
                        </div>
                        <p>{weather.wind_mph}<span style={{fontSize: '14px'}}>mph</span></p>
                        <p style={{fontSize: '10px', lineHeight: '14px', color: '#565656'}}>{weather.wind_dir} </p>
                        <p style={{fontSize: '10px', lineHeight: '14px', color: '#565656'}}> Month High: {weather.davis_current_observation?.wind_month_high_mph}mph </p>
                        {/* {weather.wind_dir} */}
                            <h2>Wind</h2>
                    </li>

                    <li className={styles.listItem}>
                        <div className={styles.svg}>
                           <Barometer />
                        </div>

                        {
                            isNaN(Number(weather.pressure_mb/ 1.333).toFixed(1)) ?  <Spinner /> : <p>{Number(weather.pressure_mb/ 1.333).toFixed(1)}<span style={{fontSize: '14px'}}>mmHg</span></p>
                    
                        }

                        <p style={{fontSize: '10px', lineHeight: '14px'}}> Month High: {(Number(weather?.davis_current_observation?.pressure_month_high_in * 25.4).toFixed(1))}mmHg</p>
                        <p style={{fontSize: '10px', lineHeight: '14px'}}> Month Low: {(Number(weather?.davis_current_observation?.pressure_month_low_in * 25.4).toFixed(1))}mmHg</p>
                       
                      
                            <h2>Pressure</h2>
                    </li>


                    <li className={styles.listItem} style={{backgroundColor: '#eeffdb'}}>
                        <div className={styles.svg}>
                           <Time />
                        </div>
                        {/* <p>{Number(weather.pressure_mb/ 1.333).toFixed(1)}</p> */}
                        <h2 style={{color: '#565656', fontSize: '12px', lineHeight: '14px', padding: '0px 20px'}}>APLORI's Weather Conditions. {weather.observation_time}</h2>
                    </li>
                   
                    
                </ul>
            </div>
        </div>
    )
}

export default Weather