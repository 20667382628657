import { 
    CREATE_SPECIES,
    UPDATE_SPECIES,
    DELETE_SPECIES,
    FETCH_SPECIES,
    FETCH_SPECIE,
    FETCH_SPECIES_BY_SEARCH,
    START_LOADING,
    END_LOADING
  } from './constants';
  import * as api from '../api/index.js';
  
  export const getSpecie = (slug) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })
      const { data } = await api.fetchSpecie(slug);
  
      dispatch({ type: FETCH_SPECIE, payload: data });
      dispatch({ type: END_LOADING })
  
    } catch (error) {
      console.log(error.response);
    } };
  
  // export const getPosts = () => async (dispatch) => {
  //   try {
  //     dispatch({ type: START_LOADING })
  //     const { data } = await api.fetchPosts();
  //     // console.log(data.title)
  
  //     dispatch({ type: FETCH_ALL, payload: data });
  //     dispatch({ type: END_LOADING })
  
  //   } catch (error) {
  //     console.log(error);
  //   } };
  
  
  export const getSpecies = (page) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data: { data, currentPage, numberOfPages, total } } = await api.fetchSpecies(page);
  
      dispatch({ type: FETCH_SPECIES, payload: { data, currentPage, numberOfPages, total } });
   
      console.log(data)

      dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error);
    }
  };
  
  export const getSpeciesBySearch = (searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data: { data } } = await api.fetchSpeciesBySearch(searchQuery);
  
      dispatch({ type: FETCH_SPECIES_BY_SEARCH, payload: { data } });
      dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error);
    }
  };
  
  export const createSpecies = (post, openSnackbar) => async (dispatch) => {
    try {
      // dispatch({ type: START_LOADING })
      const { data } = await api.createSpecies(post);
      // history.push(`/posts/${data._id}`)
      // dispatch({ type: HIDE_MESSAGE });
      dispatch({ type: CREATE_SPECIES, payload: data });
      openSnackbar('Species added successfully')
      // dispatch({ type: DISPLAY_MESSAGE });
      // dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.response);
    }
  };
  
  export const updateSpecies = (id, post, openSnackbar) => async (dispatch) => {
    try {
      const { data } = await api.updateSpecies(id, post);
  
      dispatch({ type: UPDATE_SPECIES, payload: data });
      openSnackbar('Species updated successfully')
    } catch (error) {
      console.log(error);
    }
  };
  
  
  export const deleteSpecies = (id, openSnackbar) => async (dispatch) => {
    try {
     await api.deleteSpecies(id);
  
      dispatch({ type: DELETE_SPECIES, payload: id });
      openSnackbar('Species deleted successfully')
    } catch (error) {
      console.log(error);
    }
  };
  