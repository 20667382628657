import React from 'react'
import styles from './Hero.module.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useHistory } from 'react-router-dom'
import { checkStatus } from '../../../../utils/utils'

const Hero = ({ species }) => {

    const history = useHistory()

    return (
        <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false} interval={5000}>
        <div className={styles.bigImage} style={{backgroundImage: 'url("https://res.cloudinary.com/wabis/image/upload/v1637234650/special/C8_FOX_KESTREL_t72kcc.jpg")'}}>
            <div className={styles.overlay}>
                <div className={styles.content}>
                    <div>
                        <p className={styles.title} >Birds of Nigeria</p>
                        <p className={styles.subTitle} >Compiled by The A.P. Leventis Ornithological Research Institute (APLORI)</p>
                        {/* <p>Species account of Nigerian bird species</p> */}
                    </div>
                </div>
            </div>
        </div>

        {
            species.slice(0, 4).map((specie) => (
                <div className={styles.birdInfo}>
                    <div className={styles.birdStat}>
                        <div className={styles.statusBox}>
                        <div className={checkStatus(specie.status)}>{specie.status}</div>
                        </div>
                        <h1 className={styles.comName} >{specie.name}</h1>
                        <h4 className={styles.sciName}>{specie.scientificName}</h4>
                        <button onClick={()=> history.push(`/birds/${specie.slug}`)} className={styles.readmore}>Read More</button>
                        
                    </div>
                    <div className={styles.imgContainer}>
                        <img className={styles.birdImg} src={(specie.fileUrl)} alt={specie.name}/>
                    </div>
                </div>
            ))
        }
        

        

        </Carousel>
    )
}

export default Hero
