/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'react-simple-snackbar'
import { useSelector, useDispatch  } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import styles from '../AddPost/AddPost.module.css'
import PostType from './PostType'
import Input from './Input'
import Editor from './Editor';
import Uploader from './Uploader'
import Authors from './Authors'

import { createPost, updatePost } from '../../actions/posts'
import { getProfiles } from '../../actions/profile';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#dddddd',
    color: 'black',
    height: '58px',
    marginBottom: '25px',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function AddPost({ setOpen, open, currentId, setCurrentId }) {

  const classes = useStyles();

  const [postData, setPostData] = useState({ 
    postType: null,
    author: '',
    userId: '',
    email: '',
    fileUrl: '',
    title: '',
    authors: [],
    content: '',
    journal: '',
    year: '',
    doi: '',
    avatar: '',
    publicationNumber: '',
    isPrivate: false,
    isApproved: false,
  }
)

let currentUser = []
const dispatch = useDispatch()
const post = useSelector((state) => (currentId ? state.posts.posts.find((message) => message._id === currentId) : null));
const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
const { profiles  } = useSelector((state) => state.profiles);
currentUser = profiles.filter((p) => p?.email === user?.result?.email)
// eslint-disable-next-line 
const [openSnackbar, closeSnackbar] = useSnackbar()



useEffect(() => {
  if (post) {
    setPostData(post);
    // setPostData({...post, fileUrl: post.fileUrl})
  }
}, [post]);

useEffect(() => {
  setUser(JSON.parse(localStorage.getItem('profile')))
},[postData])

useEffect(() => {
    setPostData({...postData, authors: [...postData.authors, user?.result?.email]})
}, [dispatch])


useEffect(() => {
  dispatch(getProfiles());
},[dispatch])


const handleSubmit =(e)=> {
    e.preventDefault()
    if(currentId) {
      dispatch(updatePost(currentId, postData, openSnackbar)) 
     
    } else {
      dispatch(createPost({
        ...postData, 
        author: user?.result?.name, 
        userId: user?.result?._id, 
        userSlug: currentUser[0]?.slug,
        email: user?.result?.email, 
        avatar: currentUser[0]?.profilePicture
      }, openSnackbar
    ))
      
    }
    handleClose()
    clear()
    // window.location.href="/profile"
}

  const clear =() => {
    setCurrentId(null) 
    setPostData({ 
      postType: null,
      author: '',
      userId: '',
      fileUrl: '',
      title: '',
      authors: [],
      content: '',
      journal: '',
      year: '',
      doi: '',
      publicationNumber: '',
      isPrivate: false,
      isApproved: false,
    }
  )
}

const handleClose = () => {
  setOpen(false);
};


  const handleChange = (e) => setPostData({ ...postData, [e.target.name]: e.target.value });
  
   //Add and remove array items by toggling checkbox
   const handleApproved = (e) => {
    if(e.target.checked) {
      setPostData({...postData, isApproved: true})
  }
  else {
    setPostData({...postData, isApproved: false})
}

}


const isPdf = postData.fileUrl.split('.').pop()


  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {currentId ? 'Update Post' : 'New Post'}
            </Typography>
            
            <Grid item style={{marginRight: '30px'}}>
             {user?.result?.role === 'admin' && 
             <FormControlLabel
             control={
               <Switch
                 checked={postData.isApproved ? true : false}
                 onChange={handleApproved}
                 value={postData.isApproved}
                 color="secondary"
                 size="medium"
               />
             }
             label={postData.isApproved ? 'Approved' : 'Pending Approval'}
           />
}
            </Grid>
            <Button autoFocus variant="contained" color="secondary" onClick={handleSubmit}>
            {currentId ? 'Save' : 'Publish'}
            </Button>
          </Toolbar>
        </AppBar>

        <div className={styles.formContainer}>
        <form >
            <div className={styles.postTypes}>
              <h2 style={{paddingRight: '10px', marginTop: '-8px'}}>Select Post Type:</h2>
              <PostType postType="News"value="news" checked={postData.postType === "news" ? true : false } handleChange={handleChange} />
              <PostType postType="Publication" value="publication" checked={postData.postType === "publication" ? true : false } handleChange={handleChange} />
              <PostType postType="Thesis" value="thesis" checked={postData.postType === "thesis" ? true : false } handleChange={handleChange} />
              <PostType postType="Research" value="research" checked={postData.postType === "research" ? true : false } handleChange={handleChange} />
              <PostType postType="Document" value="document" checked={postData.postType === "document" ? true : false } handleChange={handleChange} />
              <PostType postType="Proposal" value="proposal" checked={postData.postType === "proposal" ? true : false } handleChange={handleChange} />
            </div>
            <Grid container spacing={2}>
              <Input name="title" label="Title" handleChange={handleChange} type="text" required={true} value={postData.title} />
              {postData.postType === 'publication' &&
              <>
                <Authors profiles={profiles} postData={postData} setPostData={setPostData} />
                {/* <Input name="authors" label="Authors" value={postData.authors} handleChange={handleChange} type="text" required={false} half /> */}
                <Input name="year" label="Year" value={postData.year} handleChange={handleChange} type="number" required={false} half />
                <Input name="publicationNumber" label="APLORI's Publication Number" value={postData.publicationNumber} handleChange={handleChange} type="text" required={false} half />
                <Input name="journal" label="Journal" value={postData.journal} handleChange={handleChange} type="text" required={false} half />
                <Input name="doi" label="DOI" value={postData.doi} handleChange={handleChange} type="text" required={false} half />
              </>
              }

              {postData.postType === 'thesis' || postData.postType === 'proposal' &&
              <>
                <Authors profiles={profiles} postData={postData} setPostData={setPostData} />
                <Input name="year" label="Year" value={postData.year} handleChange={handleChange} type="number" half required={false} />
              </>
              }

            {(postData.postType === 'news' || postData.postType === 'research' || postData.postType === 'document') && 
              <>
                <Uploader setPostData={setPostData} postData={postData} />
               
                {isPdf === 'pdf' &&
                    <a className={styles.viewPdf} href={postData.fileUrl} download >View PDF <i className="far fa-file-pdf"></i></a>
                    }

                  {isPdf !== 'pdf' &&
                    <img style={{width: '100px', margin: '10px'} }src={postData.fileUrl} alt="cover" />
                    }
            
              </>
              }

              {postData.postType !== null && 
              <>
                {/* <Uploader setPostData={setPostData} postData={postData} /> */}
                <Editor setPostData={setPostData} postData={postData} />
              </>
              }

              <Divider />
            </Grid>
        </form>
    </div>


      </Dialog>
    </div>
  );
}