import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { getPointCountsBySearch, getPoinAbunanceBySearch, getArrivalAndDepature } from '../../../actions/pointCounts'
import { getArrivalAndDepature } from '../../../actions/pointCounts'
import { useLocation } from 'react-router-dom'
import styles from './PointCount.module.css'
// import Chart from './Chart'
import Select from './Select'
import ArrivalDepature from './ArrivalDepature/ArrivalDepature'

import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'
// import axios from 'axios'




const PointCount = () => {

    
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
  const browserHistory = createBrowserHistory()
  browserHistory.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search)
  })

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])
  

//   const [arrivals, setArrivals] = useState([])
//   const [depatures, setDepatures] = useState([])
//   useEffect(() =>  {
//     getArriaval()
//   },[])

//   async function getArriaval() {
//     try {
//       const arrive = await axios.get('http://localhost:3001/arrival/all');
//       const depart = await axios.get('http://localhost:3001/depature/all');
//      setArrivals(arrive.data);
//      setDepatures(depart.data)
//     } catch (error) {
//       console.error(error);
//     }
//   }

//   const [combine, setCombine] = useState([])
// useEffect(() => {
//     depatures.map((dp) => {
//         arrivals.map((ar) => {
//             if((dp.englishName === ar.englishName) && (dp.occurenceYear === ar.occurenceYear)) {
//                 setCombine([...combine, {...dp, initialSeen: ar.initialSeen }])
//             }
//             // setCombine([...combine, dp])
//         })
//     })
// },[arrivals, depatures])
// console.log(combine)

    const dispatch = useDispatch()
    const location = useLocation()
    const { pointCounts } = useSelector((state) => state?.pointCounts)
    const merge = pointCounts.flat()
    console.log(merge)
    // eslint-disable-next-line
    const [searchQuery, setSearchQuery] = useState({year: 2018, species: "Willow Warbler"})


    
    // useEffect(() => {
    //     dispatch(getPoinAbunanceBySearch({year: searchQuery.year, species: searchQuery.species}))
    // },[searchQuery, dispatch, location])

    useEffect(() => {
        dispatch(getArrivalAndDepature({year: searchQuery.year, species: searchQuery.species}))
    },[searchQuery, dispatch, location])


    return (
        <div className={styles.featuresContainer} >
            <div className={styles.paragraph}>
                <h1 style={{paddingBottom: '10px'}}>Point Count Monitoring visualization tool</h1>
                <p style={{padding: '10px'}}>Select a species and visualize its migratory status based on records from our database</p>
            </div>

            {/* VISUALIZATION */}
            <div>
                <Select searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            </div>
            {/* <div>
                <Chart data={pointCounts} searchQuery={searchQuery} />
            </div> */}


            <div>
                <ArrivalDepature merge={merge} />
            </div>

        </div>
    )
}

export default PointCount
