import { 
    CREATE_REQUEST,
    UPDATE_REQUEST,
    DELETE_REQUEST,
    FETCH_REQUESTS,
    FETCH_REQUEST,
    FETCH_REQUESTS_BY_USER,
    START_LOADING,
    END_LOADING,
    FETCH_REQUESTS_BY_EQUIPMENT
  } from './constants';
  import * as api from '../api/index.js';
  
  export const getRequest = (id) => async (dispatch) => {
    try {
      // dispatch({ type: START_LOADING })
      const { data } = await api.fetchRequest(id);
  
      dispatch({ type: FETCH_REQUEST, payload: data });
      // dispatch({ type: END_LOADING })
  
    } catch (error) {
      console.log(error.response);
    } };
  
  
  export const getRequests = (page) => async (dispatch) => {
    try {
      // dispatch({ type: START_LOADING });
      const { data: { data } } = await api.fetchRequests(page);
  
      dispatch({ type: FETCH_REQUESTS, payload: data });
      // dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error.response);
    }
  };
  
  export const getRequestsByUser = (searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data: { data } } = await api.fetchRequestsByUser(searchQuery);
  
      dispatch({ type: FETCH_REQUESTS_BY_USER, payload: { data } });
      dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error);
    }
  };


  export const getRequestsByEquipment = (searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data: { data } } = await api.fetchRequestsByEquipment(searchQuery);
  
      dispatch({ type: FETCH_REQUESTS_BY_EQUIPMENT, payload: { data } });
      dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error);
    }
  };
  
  export const createRequest = (post, openSnackbar) => async (dispatch) => {
    try {
      // dispatch({ type: START_LOADING })
      const { data } = await api.createRequest(post);
      // history.push(`/posts/${data._id}`)
      // dispatch({ type: HIDE_MESSAGE });
      dispatch({ type: CREATE_REQUEST, payload: data });
      openSnackbar('Request submitted successfully')
      // dispatch({ type: DISPLAY_MESSAGE });
      // dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.response);
    }
  };
  
  export const updateRequest = (id, post, openSnackbar) => async (dispatch) => {
    try {
      const { data } = await api.updateRequest(id, post);
  
      dispatch({ type: UPDATE_REQUEST, payload: data });
      openSnackbar('Request updated successfully')
    } catch (error) {
      console.log(error);
    }
  };
  
  
  export const deleteRequest = (id, openSnackbar) => async (dispatch) => {
    try {
     await api.deleteRequest(id);
  
      dispatch({ type: DELETE_REQUEST, payload: id });
      openSnackbar('Record deleted successfully')
    } catch (error) {
      console.log(error);
    }
  };
  