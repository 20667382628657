import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styles from './ProposalDetail.module.css'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Avatar } from '@material-ui/core'
import moment from 'moment'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core/styles';
import parser from 'html-react-parser'
import Spinner from '../../components/Spinner/Spinner'

import { getPost } from '../../actions/posts'
import { getProfiles } from '../../actions/profile'
// import ReactShare from '../../components/ReactShare'

import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'



const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  }));


const PubDetails = () => {

    const history = useHistory()
    const classes = useStyles()
    const { post, isLoading } = useSelector((state) => state.posts)
    const { profiles } = useSelector((state) => state.profiles)
    const dispatch = useDispatch()
    const { slug } = useParams()


    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
    const browserHistory = createBrowserHistory()
    browserHistory.listen((location, action) => {
      ReactGA.pageview(location.pathname + location.search)
    })
  
    useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])
  


    useEffect(() => {
        dispatch(getPost(slug))
        dispatch(getProfiles())
        // eslint-disable-next-line
    }, [slug, dispatch])

    // MATCH PUBLICATIONS WITH THEIR CONTENTS
  const nameEmailMap = profiles.reduce((acc, {
    name,
    email,
    userId,
    profilePicture,
    slug
  }) => {
    acc[email] = {
      name,
      userId,
      profilePicture,
      slug
    };
    return acc;
  }, {})
  
  const currentArticle = [post]?.map(({
    title,
    content,
    doi,
    year,
    _id,
    slug,
    authors
  }) => ({
    title,
    content,
    _id,
    doi,
    year,
    slug,
    authors: authors.map(entry => (nameEmailMap[entry] ? { ...nameEmailMap[entry]
    } : {
      name: entry
    }))
  }));
  


//Check DOI if it starts with http, else add http to it
var url = currentArticle[0]?.doi
var pattern = /^((http|https|ftp):\/\/)/;
if(!pattern.test(url)) {
    url = "http://" + url;
}
  
  // const openUser =(id) => history.push(`/user/${id}`)

    if(!post) return null

    if(isLoading) {
        return <Spinner />
    }

    return (
        <div className={styles.layout}>
            <div className={styles.header}>
            <h3>Publication <span style={{color: '#0daa8b'}}>|</span> {moment(currentArticle[0]?.createdAt).format("MMM Do YY")}</h3>
                <h1 style={{padding: '10px', paddingLeft: '0px', fontSize: '30px', lineHeight: '30px'}}>{currentArticle[0]?.title}</h1>
                 <hr className={styles.hrLine}/>

                 <div className={classes.root}>{currentArticle[0]?.authors.map((a) => <div onClick={ a.userId ? () => history.push(`/member/${a.slug}`): null } className={styles.user} key={a.name} >{<Avatar className={classes.small}  src={a?.profilePicture} />} <span style={{fontSize: '14px', paddingLeft: '5px'}}>{a.name}</span></div>)}</div>
                 
                 <hr className={styles.hrLine}/>
                <div className={styles.author} >
                    <p style={{paddingRight: '8px'}} >Year: {currentArticle[0]?.year} </p>
                   {currentArticle[0]?.doi && (
                     <div>DOI: <a style={{color: 'blue', paddingRight: '8px'}} href={url}>{currentArticle[0]?.doi}</a> </div>
                   )}
                   
                    {/* <h3 style={{marginRight: '5px'}}> | <span style={{cursor: 'pointer', color: '#0daa8b', paddingLeft: '8px'}}  onClick={() => openUser(post?.userId)}>{post?.author}</span></h3> */}
                </div>

            </div>
            <div className={styles.body}>
                <h2 style={{paddingTop: '20px'}}>Abstract</h2>
                <div>{parser(currentArticle[0]?.content)}</div>
            </div>
            {/* <ReactShare url={`https://aplori.org/plublications/${currentArticle[0]?.slug}`} title={currentArticle[0]?.title} /> */}
        </div>
    )
}

export default PubDetails
