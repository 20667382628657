import React from 'react'
import styles from './Status.module.css'

const Status = ({ status, value, handleChange, checked }) => {
    return (
        <label className={styles.container}><p>{status}</p>
            <input type="radio" name="status" value={value} onChange={handleChange} checked={checked}/>
            <span className={styles.checkmark} />
        </label>
    )
}

export default Status
