import React, { useEffect, useState } from 'react'
import parser from 'html-react-parser'
import axios from 'axios'
import styles from './Founder.module.css'

import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'


const Founder = () => {


  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
  const browserHistory = createBrowserHistory()
  browserHistory.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search)
  })

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])


  const [fullText, setFullText] = useState({
    title: '',
    image: '',
    content: '',
})

useEffect(() => {
async function getFullText() {
    try {
      const response = await axios.get('https://aplori.herokuapp.com/posts/anastasios-p-leventis');
      console.log(response);
      setFullText({...fullText, title: response.data.title, image: response.data.fileUrl, content: response.data.content})
    } catch (error) {
      console.error(error.response);
    }
  }
  getFullText()
    // eslint-disable-next-line 
},[])



  return (
 
        <div >
        <div className={styles.containerHead}>
        </div>
        <div className={styles.containerBody}>
          <div className={styles.profilePicture}> 
            <img 
              src={fullText.image} 
              alt={fullText.name}
            />
          </div>
          <div className={styles.name}>
            <h2>{fullText.title}</h2>
          </div>


          <div className={styles.bio}>
            <div>{parser(fullText.content)}</div>
          </div>

        </div>
      </div>

    )
}

export default Founder
