import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Features.module.css'

const Features = () => {
    
    return (
       
        <div className={styles.featuresContainer} >
            <div className={styles.paragraph}>
                <h1>
                Welcome to The A.P. Leventis <span style={{color: '#68a374'}}>Ornithological Research Institute (APLORI)</span>
                </h1>
                <hr className={styles.hrLine}/>
                <p style={{lineHeight: '25px'}}> 
                    Our main purpose is capacity building, and we do this primarily by running a Masters Degree in Conservation Biology. 
                    Although we grant degrees through the University of Jos, we are an autonomous 
                    research institute answering directly to the Vice-Chancellor of the University. 
                    Our degree is European standard with teaching from invited international academics and staff of University of Jos,
                    exams moderated by staff of the St Andrews University, and external examiners from international 
                    universities such as Edinburgh, Ghent, Groningen and Oxford.
                    APLORI is directed by Dr Adams A. Chaskda. 
                    Adams gained his PhD in Conservation Biology at the University of Cape Town, 
                    South Africa and has been active in ornithological research for the past 15 years.
                </p>

                <a  className={styles.button} href="/about">&nbsp;&nbsp; About us &nbsp;&nbsp;&nbsp; <i className="fas fa-arrow-right"></i>&nbsp;&nbsp;</a>
            </div>

            <div className={styles.items}>
                <ul className={styles.autoGrid}>
                    {/* <li className={styles.listItem}>
                        <Link to="/about">
                            <img className={styles.imgThumbnail} src="https://i.postimg.cc/GtygbqQh/APLORI-building.jpg" alt="bird" />
                                <h2>About The Institute</h2>
                        </Link>
                    </li> */}

                    <li className={styles.listItem}>
                        <Link to="/msc-program">
                            <img className={styles.imgThumbnail} src="https://conbio.org/images/content_groups/Africa/Article5b_APLORI.jpg" alt="bird" />
                            <h2>MSc. Conservation Biology</h2>
                        </Link>
                    </li>

                    <li className={styles.listItem}>
                        <a href="https://cssn.aplori.org/" target="_blank" rel="noopener noreferrer">
                            <img className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1701258186/aplori/cssn_jin9zy.png" alt="bird" />
                            <h2>Center for Species Survival Nigeria</h2>
                        </a>
                    </li>

                    <li className={styles.listItem}>
                        <Link to="/research">
                            <img className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1629136113/special/hg2hdypzzuwchz1zmzec_ksesct.jpg" alt="bird" />
                            <h2>Research Activities</h2>
                        </Link>
                    </li>

                    <li className={styles.listItem}>
                        <Link to="/publications">
                            <img className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1632013587/special/abc84103-7df5-4b4d-8497-52aa2a95ac7d_z8u2vk_ko9lss.jpg" alt="bird" />
                            <h2>Publications</h2>
                        </Link>
                    </li>
                    
                </ul>
            </div>
        </div>
    )
}

export default Features
