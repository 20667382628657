
  export const founders = [
    {
        name: 'ADAMS CHASKDA Ph.D.', 
        set: 'APLORI set 2005,', 
        title: 'Director,', 
        place: 'A. P. Leventis Ornithological Research Institute, Jos, Nigeria', 
        image: 'https://res.cloudinary.com/wabis/image/upload/v1629710429/aplori/t77krzi95eoydjmigrzg.png', 
        link: '/member/adams-chaskda', 
        logo: 'https://i.postimg.cc/bv4gdCsc/aplori-logo.png', 
        website: 'https://aplori.org',
        imageIsBig: true,
    },
    {
        name: 'ADEMOLA AJAGBE', 
        set: 'APLORI set 2004,', 
        title: 'Regional managing Director,', 
        place: 'The Nature Conservancy', 
        image: 'https://res.cloudinary.com/wabis/image/upload/v1630069442/aplori/qmfcp1i5cpjpqpdpelup.jpg', 
        link: '', 
        logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRk6W1-w-PcEzLXA3PiPF0jL3PSbczUIRnUZgmgOXY&s', 
        website: 'https://www.nature.org/en-us' 
    },

    {
        name: 'MARY MOLOKWU-ODOZI Ph.D.', 
        set: 'APLORI set 2004,', 
        title: ' Country Manager', 
        place: 'Fauna & Flora International, Liberia', 
        image: 'https://res.cloudinary.com/wabis/image/upload/v1630068749/aplori/n6cj7qvm7ccdfmkdkdu5.jpg', 
        link: '', 
        logo: 'https://upload.wikimedia.org/wikipedia/en/0/0a/Fauna_and_Flora_International_%28logo%29.jpg', 
        website: 'https://www.fauna-flora.org/' 
    },

    {
        name: 'JOSEPH ONOJA Ph.D.', 
        set: 'APLORI set 2008,', 
        title: 'Director General,', 
        place: 'Nigerian Conservation Foundation, Lagos, Nigeria', 
        image: 'https://res.cloudinary.com/wabis/image/upload/v1636717772/special/rqkfrzmwvnfvve3khada_ilgjzr.jpg', 
        link: '/member/onoja-joseph-daniel', 
        logo: 'https://res.cloudinary.com/wabis/image/upload/v1636717771/special/Nigeria_NCF-280x280_qvdzbh.jpg', 
        website: 'https://ncfnigeria.org/' 
    },
    {
        name: 'SAMUEL T. IVANDE PhD.', 
        set: 'APLORI set 2009,', 
        title: 'Conservation Coordinator (Birds)', 
        place: 'Global Center for Species Survival', 
        image: 'https://res.cloudinary.com/wabis/image/upload/v1643975702/aplori/bwqutfdxrohnoncqnuox.jpg', 
        link: '/member/samuel-ivande', 
        logo: 'https://res.cloudinary.com/wabis/image/upload/v1653899847/aplori/heky12ps_400x400_eyshln.png', 
        website: 'https://www.indianapoliszoo.com/gcss/' 
    },

    {
        name: 'HOPE USIETA Ph.D.', 
        set: 'APLORI set 2008,', 
        title: 'Executive Director', 
        place: 'Leventis Foundation Nigeria', 
        image: 'https://res.cloudinary.com/wabis/image/upload/v1629711063/aplori/dhjykudw6pp4xhszqs18.png', 
        link: '', 
        logo: 'https://res.cloudinary.com/wabis/image/upload/v1636718402/special/lev_brkarn.png', 
        website: 'https://leventisfoundation.org.ng/' 
    },

    {
        name: 'STELLA EGBE Ph.D.', 
        set: 'APLORI set 2011,', 
        title: 'Species Conservation Programme Manager,', 
        place: 'Nigerian Conservation Foundation, Lagos, Nigeria', 
        image: 'https://res.cloudinary.com/wabis/image/upload/v1653899303/aplori/Stella-Egbe-2_dff2ju.jpg', 
        link: '', 
        logo: 'https://res.cloudinary.com/wabis/image/upload/v1636717771/special/Nigeria_NCF-280x280_qvdzbh.jpg', 
        website: 'https://ncfnigeria.org/' 
    },

    {
        name: 'IMONG INAOYOM Ph.D.', 
        set: 'APLORI set 2007,', 
        title: 'Cross River Landscape Director, Gorilla Project,', 
        place: 'WCS Nigeria', 
        image: 'https://res.cloudinary.com/wabis/image/upload/v1636718680/special/Imong_Headshot2_Nigeria_lmhp2m.webp', 
        link: '', 
        logo: 'https://res.cloudinary.com/wabis/image/upload/v1636718813/special/Wildlife-Conservation-Society_jnulsl.png', 
        website: 'https://nigeria.wcs.org/' 
    },

    // {
    //     name: 'CHIOMA OKAFOR', 
    //     set: 'APLORI set 2012,', 
    //     title: 'Coordinator,', 
    //     place: 'EDEN Creation Care Initiative (Arocha International affiliate in Nigeria)', 
    //     image: 'https://i.postimg.cc/7b8TLzjd/Chioma-Okafor.jpg', 
    //     link: '', 
    //     logo: 'https://i.postimg.cc/QMvNnQgv/download.jpg', 
    //     website: 'https://nigeria.arocha.org/',
    //     imageIsBig: true,
    // },

    {
        name: 'ADEWALE AWOYEMI', 
        set: 'APLORI set 2015,', 
        title: 'Manager,', 
        place: 'IITA Forest Centre', 
        image: 'https://i.postimg.cc/8CSp6mTB/download.jpg', 
        link: '', 
        logo: 'https://i.postimg.cc/JnFSbRbV/download.png', 
        website: 'https://www.iita.org' 
    },


    
   
]