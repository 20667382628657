import React, { useEffect} from 'react'
import styles from './CapacityBuilding.module.css'
import KeyRoles from './KeyRoles/KeyRoles'
import PhdStudents from './PhdStudents/PhdStudents'
import Research from './Research/Research'
import Destination from './Destination/Destination'
import Trends from './Trends/Trends'
import Sponsors from './Sponsors/Sponsors'
import DestinationChart from './Charts/Destination'
import PublicationChart from './Charts/Publication'
import AnnualPubChart from './Charts/AnnualPub'
import CitizenScience from './CitizenScience/CitizenScience'
import Coverage from './Coverage/Coverage'
import NgoFounders from './NgoFounders/NgoFounders'

import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'


const CapacityBuilding = () => {



 
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
  const browserHistory = createBrowserHistory()
  browserHistory.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search)
  })

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  
    return (
        <div className={styles.layout}>
            <section>
               
                    <div className={styles.header}>
                    <h1>23 years of Capacity Building</h1>
                    <p>For Ornithology and Conservation Science in West Africa</p>
                        <img className={styles.featuredImage} src="https://res.cloudinary.com/wabis/image/upload/v1636710062/special/Capacity-Building-cleaned_f09ajt.jpg" alt="Capacity building"></img>
                    </div>
                    <div>
                        <Destination />
                    </div>

                    <section className={styles.section}>
                    <h1>APLORI graduate destinations - October, 2023</h1>
                    <p>Since 2004 when the first set of eight students completed their programme, 
                        APLORI has trained 146 young West Africans at the Masters level in Ornithology and 
                        Conservation Biology. Over 25% of these graduates have gone ahead to complete PhDs, 
                        while another ~24% are currently studying for PhDs. Most remarkably, ~90% of APLORI 
                        graduates remain active contributors to biodiversity conservation efforts eithers as 
                        researchers, lecturers, teachers, government officials and Conservation NGO founders 
                        and workers. See below:
                    </p>
                      <DestinationChart />
                   
                    </section>

            </section>

            <section className={styles.section}>
                    <h1>Not just in Nigeria, but across West Africa</h1>
                        <p>Although APLORI’s capacity building work started in Nigeria, this has extended 
                            across the West African sub-region. Since 2012 when the first international 
                            student from Liberia was admitted to the programme, other young West Africans 
                            have participated in the APLORI M.Sc. Conservation Biology Programme. 
                            Through regional partnerships with the University of Cape Coast in Ghana; FFI – 
                            Liberia and other partners in Sierra Leone; ten Ghanaian, four Liberian and a 
                            Sierra Leonean student have all participated in the APLORI M.Sc. programme. 
                        </p>
                    <div>
                      <img src="https://res.cloudinary.com/wabis/image/upload/v1689940653/map_yf3ntc.jpg" alt="aplori capacity building" />
                    </div>
            </section>


            <section className={styles.section}>
                    <h1>APLORI graduates  in key roles & driver’s seat in leading 
                        conservation Institutions across the region</h1>
                        <p>After 23 years of quality capacity building work, some of our graduates are now in 
                            leadership positions where they are driving efforts for the conservation of 
                            biodiversity at frontline biodiversity conservation organizations including at 
                            Birdlife International, Fauna & Flora International, WCS, NCF, Leventis Foundation. 
                            Quite fitting also is the fact that APLORI is now being led by one of her graduates.
                        </p>
                    <div>
                        <KeyRoles />
                    </div>
            </section>

            <section className={styles.section}>
                    <h1>Founders of conservation NGOs</h1>
                        <p>Several other APLORI graduates have founded and are leading biodiversity conservation efforts at NGOs, both locally and internationally.
                        </p>
                    <div>
                        <NgoFounders />
                    </div>
            </section>


            <section className={styles.section}>
                    <h1 className={styles.h1}>An overview of APLORI research output (2002 – 2021)</h1>
                    <p style={{paddingBottom: '20px'}} >Besides our capacity building work, APLORI also conducts and facilitates research in tropical 
                    ornithology and ecology. Through APLORI’s work, capacity of researchers in West Africa to ask questions 
                    of the environment and investigate ecological processes, especially in tropical habitats in Africa has 
                    increased. From an average of three published articles from APLORI per annum during the first five years 
                    of her establishment, researchers from APLORI alone are now contributing an annual average of 15 
                    peer-reviewed scientific articles per annum to improve our understanding of ecological processes in 
                    tropical environments in Africa.
                    </p>
                    <div >
                        <div className={styles.pubCharts}>
                            <div className={styles.text}>
                                <div className={styles.icon}>
                                    <i style={{color: 'white', fontSize: '23px'}} className="fas fa-layer-group"></i>
                                </div>
                                <p>22 published articles by APLORI researchers & affiliates in 2021 alone - our most productive year thus far</p>
                            </div>
                            <div className={styles.chart}>
                                <PublicationChart /> 
                            </div>
                        </div>
                        <div style={{padding: '20px 0px'}}> 
                            <AnnualPubChart /> 
                        </div>
                    </div>
            </section>

            <section className={styles.section}>
                    <h1>Renewed drive for continued capacity development via PhDs in the University of Jos</h1>
                    <p>As APLORI trains young Africans, many of them go on to gain further training. 
                        Many also return to contribute to the capacity building work in the region. 
                        Over 25% of APLORI graduates are now in Universities in Nigeria and some of them 
                        are now training other PhDs. In 2021 alone, seven (7) more APLORI graduates have newly 
                        registered and started their PhDs in the Department of Zoology, University of Jos, and one at the 
                        University of Cyprus.
                        Those at the University of Jos are all supervised by former APLORI students. 
                    </p>
                    <div>
                        <PhdStudents />
                    </div>
            </section>

            <section className={styles.section}>
                    <h1>Facilitating fundamental biological research in West Africa</h1>
                    <p>After two decades of facilitating research for both local and international researchers interested in 
                        tropical ecology, clear research themes are beginning to emerge at APLORI. 
                        One research theme is focused on studies that investigate bird migration, 
                        population dynamics and monitoring. Another is a Macroecology and Biogeography research 
                        theme that includes studies that investigate habitat use by birds and how changes in 
                        habitat cover and structure affects the distribution of birds. More recently, 
                        there has been increased interest in the use of molecular techniques to increase 
                        understanding of population genetics and disease ecology leading to the setting up of an 
                        APLORI Molecular Ecology lab and a researcher cluster around this theme. 
                        There is also increasing interest and capacity to improve understanding of the human 
                        dimensions of biodiversity conservation and hence an Ethnoecology research cluster is 
                        beginning to develop. With advances in technology and after two decades of active research, 
                        APLORI, through a Global Biodiversity Information Facility GBIF grant is setting up a Biodiversity 
                        Information Development Unit that is working to develop tools and systems for efficient biodiversity 
                        data gathering, management and utilization.
                    </p>
                    <div>
                        <Research />
                    </div>
            </section>


            <section className={styles.section}>
                    <h1 >Trends in APLORI Research Projects</h1>
                    <p>A quick analysis of research trends at APLORI shows a progressive increase in the number 
                        and diversity of research topics. From a lot of research topics mainly focused on 
                        simple species inventories during the first six years (2004 – 2009), 
                        the last six years (2016 – 2021) has seen remarkable increase in the diversity and 
                        sophistication of research topics by researchers at APLORI, including in new and 
                        emerging research fields e.g. urban ecology, citizen science, ethnoconservation etc.
                    </p>
                    <div>
                        <Trends />
                    </div>
            </section>

            <section className={styles.section}>
                    <h1>Promoting citizen science & building an environmentally conscious society</h1>
                    <p>In recent times (since 2015), APLORI has also begun directing efforts towards 
                        increasing public awareness and engagement in biodiversity conservation through 
                        birdwatching. This work has mainly been implemented through the Nigerian Bird Atlas 
                        Project NiBAP – a citizen science project that recruits, trains and organizes young 
                        Nigerians into bird clubs and bird atlas teams. From just two largely expat-dominated bird 
                        clubs in Nigeria, the NiBAP has now founded and established 20 bird clubs with over 
                        1,000 mostly indigenous members. From three active atlasers in 2015, there are now over 
                        250 active bird atlasers in Nigeria. All these citizen scientists have helped to survey 
                        close to 4000 of the 11,080 pentads needed for a complete survey coverage of Nigeria. 
                        The NiBAP Facebook group has close to 5,000 people participating in the general discourse about birds and biodiversity conservation in Nigeria.   
                    </p>
                    <div>
                        <CitizenScience />
                    </div>
                    <div>
                        <Coverage />
                    </div>
            </section>

            <section className={styles.section}>
                    <h1 >Partners & collaborators</h1>
                    <p>All of this capacity building work has been made possible because of the vision and 
                        commitment of our benefactor, Dr. A. P. Leventis and the Tasso Leventis Conservation 
                        Foundation; and through the support of several vital partners and collaborators including 
                        the University of Jos; the Nigerian Conservation Foundation; the Laminga Community; 
                        Leventis Foundation Nigeria; BirdLife International – Cambridge and Nairobi; University of 
                        St Andrews, UK; University of Cape Coast, Ghana, just to mention a few.  Our citizen
                        science efforts have also enjoyed great support from several collaborators and partners 
                        including: The FitzPatrick Institute for African Ornithology, University of Cape Town, 
                        South Africa; the Biodiversity Development Institute (BDI), South Africa; Skandinav Konsult, 
                        Sweden; Swiss Ornithological Institute, Switzerland; BirdLife Sweden; BirdLife – 
                        The Netherlands, the British Trust for Ornithology; the Royal Society for the Protection of 
                        Birds RSPB etc  

                    </p>
                    <div>
                      <Sponsors />
                    </div>
            </section>

        </div>
    )
}

export default CapacityBuilding
