import React from 'react'
import styles from './Footer.module.css'
import { Link } from 'react-router-dom'


const Footer = () => {


    var newDate = new Date();
    var thisYear = newDate.getFullYear();
    
    return (
        <div className={styles.featuresContainer} >
            <div className={styles.items}>
                <ul className={styles.autoGrid}>
                    <li className={`${styles.listItem} ${styles.logoContainer}`}>
                        <img className={styles.imgThumbnail} src="https://i.postimg.cc/bv4gdCsc/aplori-logo.png" alt="aplori-logo" />
                    </li>

                    <li className={styles.listItem}>
                        <h2>Contact us</h2>
                        <ul>
                            <li>
                                P.O.Box 13404, Laminga,
                                Jos-East LGA,
                                Plateau State, 930001, Nigeria.<br/>
                                Phone: +234 903 365 5620  <br/>
                                Email: admin@aplori.org 
                            </li>
                        </ul>
                    </li>

                    <li className={styles.listItem}>
                        <h2>Quick Links</h2>
                        <ul>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/msc-program">MSc Program</Link></li>
                            <li><Link to="/research">Research</Link></li>
                            <li><Link to="/publications">Publications</Link></li>
                            <li><Link to="/news">News</Link></li>
                        </ul>
                    </li>

                    <li className={styles.listItem}>
                        <h2>Quick links</h2>
                        <ul>
                            <li><Link to="/people/board-members">Board Members</Link></li>
                            <li><Link to="/people/staff">Staff</Link></li>
                            <li><Link to="/people/affiliates">Affiliates</Link></li>
                            <li><Link to="/people/students">Students</Link></li>
                            <li><Link to="/people/alumni">Alumni</Link></li>
                        </ul>
                       
                    </li>

                    <li className={styles.listItem}>
                        <h2>ABC App</h2>
                        <ul>
                            <li><a href="https://play.google.com/store/apps/details?id=com.pagesuite.africanbirdclub" target="_blank" rel="noreferrer"><img style={{width: '140px', cursor: 'pointer'}} src="https://i.postimg.cc/8PcQfW3g/playstore.png" alt="bird-id-app" /></a></li>
                            <li><a href="https://apps.apple.com/om/app/african-bird-club/id1468097668" target="_blank" rel="noreferrer" ><img style={{width: '140px', cursor: 'pointer'}} src="https://i.postimg.cc/ZKfzs0rx/Apple-badge.png" alt="bird-id-app" /></a></li>
                            <li>
                            <h3 className={styles.soc}>Follow us on</h3>
                            <div>
                                <a href="https://web.facebook.com/aplori1" target="_blank" rel="noreferrer"><i className="fab fa-facebook"></i></a>
                                <a href="https://twitter.com/aploritwit1" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a>
                                <i className="fab fa-instagram"></i>
                                <i className="fab fa-linkedin-in"></i>
                            </div>
                            </li>
                        </ul>
                       
                    </li>
                    
                </ul>
            </div>

            <div className={styles.footerBottom}>
                <div className={styles.bottom}>
                    <p>{`Copyright ${thisYear}, The A.P. Leventis Ornithological Research Institute`}</p>
                </div>
            </div>

        </div>
      
    )
}

export default Footer
