 /* eslint-disable */
 import React, { useState, useEffect} from 'react';
 import { useLocation } from 'react-router-dom';
 import { withStyles } from '@material-ui/core/styles';
 import Button from '@material-ui/core/Button';
 import Dialog from '@material-ui/core/Dialog';
 import MuiDialogTitle from '@material-ui/core/DialogTitle';
 import MuiDialogContent from '@material-ui/core/DialogContent';
 import MuiDialogActions from '@material-ui/core/DialogActions';
 import IconButton from '@material-ui/core/IconButton';
 import CloseIcon from '@material-ui/icons/Close';
 import Typography from '@material-ui/core/Typography';
 
 import { useDispatch, useSelector } from 'react-redux'
 import { createEquipment, updateEquipment } from '../../actions/equipment'
 import Uploader from './Uploader/Uploader';
 
 const styles = (theme) => ({
   root: {
     margin: 0,
     padding: theme.spacing(2),
     backgroundColor: '#3e6947',
     marginLeft: 0,
   },
   closeButton: {
     position: 'absolute',
     right: theme.spacing(1),
     top: theme.spacing(1),
     color: 'white',
   },
 });
 
 const DialogTitle = withStyles(styles)((props) => {
   const { children, classes, onClose, ...other } = props;
   return (
     <MuiDialogTitle disableTypography className={classes.root} {...other}>
       <Typography variant="h6">{children}</Typography>
       {onClose ? (
         <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
           <CloseIcon />
         </IconButton>
       ) : null}
     </MuiDialogTitle>
   );
 });
 
 const DialogContent = withStyles((theme) => ({
   root: {
     padding: theme.spacing(3),
   },
 }))(MuiDialogContent);
 
 const DialogActions = withStyles((theme) => ({
   root: {
     margin: 0,
     padding: theme.spacing(1),
   },
 }))(MuiDialogActions);
 
 const NewEquipment = ({ 
      setOpen, 
      open, 
      currentEquipment, 
      setCurrentEquipment, 
      equipmentData, 
      setEquipmentData, 
      openSnackbar 
    }) => {
     const location = useLocation()

     const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
     const dispatch = useDispatch()
 
     useEffect(() => {
       setUser(JSON.parse(localStorage.getItem('profile')))
       // setEquipmentData({...equipmentData, userId: user?.result?._id})
     },[location])
 
 
     useEffect(() => {
       var checkId = user?.result?._id
       if(checkId !== undefined) {
         setEquipmentData({...equipmentData, userId: [checkId]})
       } else {
         setEquipmentData({...equipmentData, userId: [user?.result?.googleId]})
       }
       
     },[location])
 
 
     const handleSubmitEquipment =(e)=> {
         e.preventDefault()
         if(currentEquipment) {
           dispatch(updateEquipment(currentEquipment, equipmentData, openSnackbar))
         } else {
           dispatch(createEquipment(equipmentData, openSnackbar))
         }
         
         clear()
         handleClose()
        //  window.location.reload()
     }
 
   const clear =() => {
     setCurrentEquipment(null) 
     setEquipmentData({
        name: '', 
        quantity: '', 
        borrowed: 0,
        imageUrl: '',
        userId: []
     })
   }
     
   
   const handleClose = () => {
     setOpen(false);
   };
 
   const inputStyle = {
     display: "block",
     padding: "1.4rem 0.75rem",
     width: "100%",
     fontSize: "0.9rem",
     lineHeight: 1.25,
     color: "#55595c",
     backgroundColor: "#fff",
     backgroundImage: "none",
     backgroundClip: "padding-box",
     borderTop: "0",
     borderRight: "0",
     borderBottom: "1px solid #eee",
     borderLeft: "0",
     borderRadius: "3px",
     transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)"
 }

 const postTypes = {
  display: 'flex',
  alignItems: 'center',
  justifycontent: 'center',
  paddingTop: '15px',
  flexWrap: 'wrap'
}

 
   return (
     <div>
         <form >
       <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
             <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{paddingLeft: '20px', color: 'white'}}>
             {currentEquipment? `Update ${equipmentData.name}` : "New Equipment"}
             </DialogTitle>
             <DialogContent dividers>
 
 
             <div className="customInputs">
              
               {user?.result?.isStoreAdmin && (
                 <>
                 <Uploader equipmentData={equipmentData} setEquipmentData={setEquipmentData} />

                <input 
                 placeholder="Name" 
                 style={inputStyle} 
                 name='name' 
                 type='text'  
                 onChange={(e) => setEquipmentData({...equipmentData, name: e.target.value})}
                 value={equipmentData.name} 
               />

              </>
               )}

                <input 
                 placeholder="Quantity" 
                 style={inputStyle} 
                 name='quantity' 
                 type='Number' 
                 onChange={(e) => setEquipmentData({...equipmentData, quantity: e.target.value})}
                 value={equipmentData.quantity} 
               />

           </div>
 
             </DialogContent>
             <DialogActions>
             <Button  onClick={handleSubmitEquipment}  variant="contained" style={{marginRight: '25px'}} >
             {currentEquipment? 'Update' : 'Submit'}
             </Button>
             </DialogActions>
       </Dialog>
         </form>
     </div>
   );
 }
 
 export default NewEquipment