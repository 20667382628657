import React from 'react'
import ReactApexChart from 'react-apexcharts';

class ApexChart extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
      
        series: [{
          name: 'Average',
          data: [3.2, 8.4, 14.2, 15]
        }],
        options: {
          chart: {
            height: 350,
            type: 'bar',
            zoom: { enabled: false },
            toolbar: {show: false},
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              columnWidth: '50%',
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "";
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },

          
          xaxis: {
            categories: ["2002-2006", "2007-2011", "2021-2016", "2017-2021"],
            position: 'bottom',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },
            tooltip: {
              enabled: true,
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val + "";
              }
            }
          
          },
          title: {
            text: '',
            margin: 10,
            floating: true,
            offsetY: 330,
            align: 'center',
            style: {
              color: '#444',
            },
          }
        },
      
      
      };
    }

  

    render() {
      return (
        

  <div id="chart">
    <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
    <p style={{fontSize: '16px', lineHeight: '17px', marginTop: '-40px'}}><strong>APLORI Publication (2002-2021) 5 year averages</strong></p>
</div>

      );
    }
  }


  export default ApexChart
