import React from 'react'
import styles from './Research.module.css'
import { useHistory } from 'react-router-dom'


const PhdStudents = () => {

    const history = useHistory()
    const openPost =(slug) => history.push(`${slug}`)

    return (
      <div className={styles.featuresContainer} >
            <div className={styles.items}>
                <ul className={styles.autoGrid}>

                    <li className={styles.listItem}>
                        <img onClick={() => openPost('/')} className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1636964986/special/Bird_migration_cuwhs0.jpg" alt="bird migration" />
                        <h2 style={{cursor: 'pointer', textAlign: 'center'}} onClick={() => openPost('/')} >Bird migration/Avian Population dynamics & Monitoring</h2>
                        <p style={{fontSize: '12px', lineHeight: '14px', textAlign: 'left', marginTop: '-10px'}}><em>"Seasonality & moult in the Northern red Bishop"</em></p>
                    </li>

                    <li className={styles.listItem}>
                        <img onClick={() => openPost('/research/bioinformatics')} className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1628767832/aplori/tzih9pqspalcnbjdqzff.jpg" alt="biodiversity information unit" />
                        <h2 style={{cursor: 'pointer', textAlign: 'center'}} onClick={() => openPost('/research/bioinformatics')} >Biodiversity Information Development</h2>
                        <p style={{fontSize: '12px', lineHeight: '14px', textAlign: 'left', marginTop: '-10px'}}><em>“Integrated information systems management - (website, APLORI community data, biodiversity data & equipment database)”</em></p>
                    </li>

                    <li className={styles.listItem}>
                        <img onClick={() => openPost('/research/west-african-centre-for-emerging-infectious-diseases-wac-eid')} className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1629476185/aplori/mzlka2ixmche0hpfciut.jpg" alt="conservation genetics" />
                        <h2 style={{cursor: 'pointer', textAlign: 'center'}} onClick={() => openPost('/research/west-african-centre-for-emerging-infectious-diseases-wac-eid')} >Conservation genetics/Molecular ecology/Disease ecology</h2>
                        <p style={{fontSize: '12px', lineHeight: '14px', textAlign: 'left', marginTop: '-10px'}}><em>"“Population genetics of Afrotropical birds”"</em></p>
                    </li>

                    <li className={styles.listItem}>
                        <img onClick={() => openPost('/research/macroecology-and-biogeography')} className={styles.imgThumbnail} src="https://i.postimg.cc/QMR5fZtQ/Amurum.jpg" alt="Macroecology and Biogeography" />
                        <h2 style={{cursor: 'pointer', textAlign: 'center'}} onClick={() => openPost('/research/macroecology-and-biogeography')} >Macroecology and Biogeography (habitat use, fragmentation & species distribution)</h2>
                        <p style={{fontSize: '12px', lineHeight: '14px', textAlign: 'left', marginTop: '-10px'}}><em>"Amurum Forest Reserve: a refugia for biodiversity"</em></p>
                    </li>

                    <li className={styles.listItem}>
                        <img onClick={() => openPost('/research/socioecology-and-ethnoecology')} className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1628768043/aplori/h9megkmzizwae8guaw3c.jpg" alt="Macroecology and Biogeography" />
                        <h2 style={{cursor: 'pointer', textAlign: 'center'}} onClick={() => openPost('/research/socioecology-and-ethnoecology')} >Socioecology & ethnoecology/Conservation Education</h2>
                        <p style={{fontSize: '12px', lineHeight: '14px', textAlign: 'left', marginTop: '-10px'}}><em>"Two decades of conservation effort in AFR: perceptions, attitude and knowledge of catchment communities"</em></p>
                    </li>      

                </ul>
            </div>


        </div>
    )
}

export default PhdStudents
