import { 
    CREATE_SPECIES,
    UPDATE_SPECIES,
    DELETE_SPECIES,
    FETCH_SPECIES,
    FETCH_SPECIE,
    FETCH_SPECIES_BY_SEARCH,
    START_LOADING,
    END_LOADING 
} from '../actions/constants';

const species = (state = { isLoading: true, message: false, species: [] }, action) => {
  switch (action.type) {
    case START_LOADING:
      return {...state, isLoading: true }
    case END_LOADING:
      return {...state, isLoading: false }

    case FETCH_SPECIES:
      return {
        ...state,
        species: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
        total: action.payload.total
      };

    case FETCH_SPECIES_BY_SEARCH:
        return { ...state, species: action.payload.data };
    case FETCH_SPECIE:
      return {...state, specie: action.payload }
    case CREATE_SPECIES:
      return {...state, species: [...state.species, action.payload]}
    case UPDATE_SPECIES:
      return {...state, species: state.species.map((post) => (post._id === action.payload._id ? action.payload : post))}
    case DELETE_SPECIES:
      return {...state, species: state.species.filter((post) => post._id !== action.payload)}
    default:
      return state;
  }
};

export default species
