import React from 'react'
import styles from './Trends.module.css'


const PhdStudents = () => {


    return (
      <div className={styles.featuresContainer} >
            <div className={styles.items}>
                <ul className={styles.autoGrid}>

                    <li className={styles.listItem}>
                        <img  className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1638798644/special/species_inventory_tu3xm8.png" alt="trends in ecology" />
                        <h2 style={{cursor: 'pointer', textAlign: 'center'}} >2004 to 2009</h2>
                    </li>

                    <li className={styles.listItem}>
                        <img  className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1638798643/special/disturbance_xklxi1.png" alt="trends in ecology" />
                        <h2 style={{cursor: 'pointer', textAlign: 'center'}} >2010-2015</h2>
                    </li>

                    <li className={styles.listItem}>
                        <img className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1638798643/special/habitatuse_ikwdif.png" alt="trends in ecology" />
                        <h2 style={{cursor: 'pointer', textAlign: 'center'}}>2016 to 2021</h2>
                    </li>


                </ul>
            </div>


        </div>
    )
}

export default PhdStudents
