import React from 'react';
import { Box, Typography, Container, Button, Grid, Paper, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
  },
  header: {
    backgroundImage: `url(https://res.cloudinary.com/wabis/image/upload/v1626588177/special/Amurum_2_vkebhw.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
    textAlign: 'center',
    padding: theme.spacing(10),
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: 1,
    },
    '& > *': {
      position: 'relative',
      zIndex: 2,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5), // Adjust padding for small screens
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2), // Further adjust for extra small screens
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem', // Adjust font size for smaller screens
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem', // Further adjust for extra small screens
    },
  },
  content: {
    marginTop: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  applyButton: {
    marginTop: theme.spacing(2),
    backgroundColor: '#e53935', // Red color
    color: 'white',
    boxShadow: '0 8px 20px rgba(229, 57, 53, 0.5)', // Soft shadow
    '&:hover': {
      backgroundColor: '#c62828',
    },
  },
}));

const Communication = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {/* Hero Header Section */}
      <Box className={classes.header}>
        <Typography variant="h3" className={classes.title} style={{ color: "#fff", paddingTop: '90px' }}>
          Job Vacancy: Communications Officer [Closed]
        </Typography>
      </Box>

      {/* Content Section */}
      <Container maxWidth={false} className={classes.content}>
        <Paper elevation={3} style={{ padding: '24px' }}>
          {/* Description Section */}
          <Box className={classes.section}>
            <Typography variant="h5" gutterBottom>
              About APLORI
            </Typography>
            <Typography variant="body1">
              The A.P. Leventis Ornithological Research Institute (APLORI) is a Center of Excellence in ornithological 
              training and research under the University of Jos, Nigeria. Fully funded by the Leventis Foundation since 2001, 
              APLORI develops capacity for conservation in West Africa, advances knowledge of African bird ecology, 
              and conserves one of Nigeria’s Important Bird Areas, the Amurum Forest Reserve.
            </Typography>
          </Box>

          <Divider />

          {/* Job Description Section */}
          <Box className={classes.section}>
            <Typography variant="h5" gutterBottom>
              Job Summary: Communications Officer
            </Typography>
            <Typography variant="body1">
              We are seeking a highly motivated and well-qualified candidate to fill the role of **Communications Officer**. 
              The successful candidate will coordinate media relations, develop newsletters, draft and edit public content, 
              manage internal and external communications, and promote the Center’s activities locally and internationally.
            </Typography>
          </Box>

          {/* Responsibilities Section */}
          <Box className={classes.section}>
            <Typography variant="h6" gutterBottom>
              Primary Responsibilities:
            </Typography>
            <ul>
              <li>Coordinate media relations with the Center.</li>
              <li>Develop and circulate the Center’s quarterly newsletter.</li>
              <li>Draft and edit content for public outreach and the website.</li>
              <li>Manage social media accounts and communication channels.</li>
              <li>Develop publicity strategies to promote key activities.</li>
              <li>Create press releases and maintain a favorable public image.</li>
            </ul>
          </Box>

          {/* Requirements Section */}
          <Box className={classes.section}>
            <Typography variant="h6" gutterBottom>
              Basic Requirements:
            </Typography>
            <ul>
              <li>BA/BSc in public relations, mass communication, media studies, or related fields.</li>
              <li>NYSC discharge certificate.</li>
              <li>MA/MSc in related fields is an added advantage.</li>
              <li>Membership in professional bodies is a plus.</li>
            </ul>
          </Box>

          {/* Additional Skills Section */}
          <Box className={classes.section}>
            <Typography variant="h6" gutterBottom>
              Essential Skills:
            </Typography>
            <ul>
              <li>Ability to synthesize and report scientific content.</li>
              <li>Excellent writing and communication skills.</li>
              <li>Team-building and leadership abilities.</li>
              <li>Proficiency in computer applications.</li>
              <li>Ability to meet deadlines and communicate effectively in English.</li>
            </ul>
          </Box>

          <Divider />

          {/* Application Method Section */}
          <Box className={classes.section}>
            <Typography variant="h6" gutterBottom>
              Method of Application:
            </Typography>
            <Typography variant="body1">
              Interested candidates should click on the button below and send their Application to the Director, 
              A.P. Leventis Ornithological Research Institute, Laminga, Jos East LGA, Plateau State, Nigeria.
            </Typography>
            <Typography variant="body1" color="error" style={{ marginTop: '8px' }}>
              Application Deadline: Wednesday, 23rd October, 2024, 5:00 PM (West Africa Time).
            </Typography>
            <Typography variant="body2" style={{ marginTop: '8px' }}>
              <em>Note: Only shortlisted candidates will be contacted.</em>
            </Typography>
          </Box>

          {/* Apply Button */}
          <Grid container justifyContent="center" style={{ marginBottom: '16px' }}>
            <Grid item>
              {/* <a href="https://forms.gle/MhjsEhErE9wCLRKe7" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}> */}
              {/* <a href="https://apply.aplori.org/communications-officer-form/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}> */}
            
                <Button variant="contained" color="primary" className={classes.applyButton} disabled >
                  Closed
                </Button>
       
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default Communication;
