export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_POST = 'FETCH_POST';
export const FETCH_BY_USER = 'FETCH_BY_USER';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH'

export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_NEWS = 'FETCH_NEWS'
export const FETCH_PUBLICATIONS = 'FETCH_PUBLICATIONS'
export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS'
export const FETCH_RESEARCH = 'FETCH_RESEARCH'
export const FETCH_THESES = 'FETCH_THESES'
export const FETCH_PROPOSALS = 'FETCH_THESES'

export const START_LOADING = "START_LOADING"
export const END_LOADING = "END_LOADING"

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const RESET = 'RESET';
export const FORGOT = 'FORGOT';

export const CREATE_PROFILE = 'CREATE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const FETCH_PROFILES = 'FETCH_PROFILES';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_BY_USER = 'FETCH_PROFILE_USER';
export const FETCH_PROFILES_BY_USER = 'FETCH_PROFILES_BY_USER';

export const CREATE_SPECIES = 'CREATE_SPECIES';
export const UPDATE_SPECIES = 'UPDATE_SPECIES';
export const DELETE_SPECIES = 'DELETE_SPECIES';
export const FETCH_SPECIES = 'FETCH_SPECIES';
export const FETCH_SPECIE = 'FETCH_SPECIE';
export const FETCH_SPECIES_BY_SEARCH = 'FETCH_SPECIES_BY_SEARCH';


export const CREATE_REQUEST = 'CREATE_REQUEST';
export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const DELETE_REQUEST = 'DELETE_REQUEST';
export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_REQUESTS = 'FETCH_REQUESTS';
export const FETCH_REQUESTS_BY_USER = 'FETCH_REQUESTS_BY_USER';
export const FETCH_REQUESTS_BY_EQUIPMENT = 'FETCH_REQUESTS_BY_EQUIPMENT';

export const CREATE_EQUIPMENT = 'CREATE_EQUIPMENT';
export const UPDATE_EQUIPMENT = 'UPDATE_EQUIPMENT';
export const DELETE_EQUIPMENT = 'DELETE_EQUIPMENT';
export const FETCH_EQUIPMENT = 'FETCH_EQUIPMENT';
export const FETCH_EQUIPMENTS = 'FETCH_EQUIPMENTS';
export const FETCH_EQUIPMENTS_BY_USER = 'FETCH_EQUIPMENTS_BY_USER';
export const FETCH_EQUIPMENTS_BY_SEARCH = 'FETCH_EQUIPMENTS_BY_SEARCH';

export const CREATE_POINTCOUNT = 'CREATE_POINTCOUNT';
export const UPDATE_POINTCOUNT = 'UPDATE_POINTCOUNT';
export const DELETE_POINTCOUNT = 'DELETE_POINTCOUNT';
export const FETCH_POINTCOUNT = 'FETCH_POINTCOUNT';
export const FETCH_POINTCOUNTS = 'FETCH_POINTCOUNTS';
export const FETCH_POINTCOUNTS_BY_SEARCH = 'FETCH_POINTCOUNTS_BY_SEARCH';