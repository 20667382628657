import { 
    CREATE_EQUIPMENT,
    UPDATE_EQUIPMENT,
    DELETE_EQUIPMENT,
    FETCH_EQUIPMENTS,
    FETCH_EQUIPMENT,
    FETCH_EQUIPMENTS_BY_USER,
    START_LOADING,
    END_LOADING,
    FETCH_EQUIPMENTS_BY_SEARCH
  } from '../actions/constants';
  
  const equipments = (state = { isLoading: true, equipments: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return {...state, isLoading: true }
      case END_LOADING:
        return {...state, isLoading: false }
  
      case FETCH_EQUIPMENTS:
        return {
          ...state,
          equipments: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
          total: action.payload.total
        };


        case FETCH_EQUIPMENTS_BY_SEARCH:
          return { ...state, equipments: action.payload.data };
     
  
      case FETCH_EQUIPMENTS_BY_USER:
          return { ...state, equipments: action.payload.data };
      case FETCH_EQUIPMENT:
        return {...state, equipment: action.payload }
      case CREATE_EQUIPMENT:
        return {...state, equipments: [...state.equipments, action.payload]}
      case UPDATE_EQUIPMENT:
        return {...state, equipments: state.equipments.map((post) => (post._id === action.payload._id ? action.payload : post))}
      case DELETE_EQUIPMENT:
        return {...state, equipments: state.equipments.filter((post) => post._id !== action.payload)}
      default:
        return state;
    }
  };
  
  export default equipments
  