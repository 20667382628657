
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'react-simple-snackbar'
import { useSelector, useDispatch  } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Grid } from '@material-ui/core';
import LoadingBar from 'react-top-loading-bar'
import styles from '../AddPost/NewSpecies.module.css'
import Input from './Input'
import Uploader from './Uploader'

import { createSpecies, updateSpecies, getSpecie } from '../../../../actions/species'
import { getProfiles } from '../../../../actions/profile'; 
import MapUploader from './MapUploader';
import TinyMCE from './TinyMCE/TinyMCE'


// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export default function AddPost({ setOpen, open, currentId, setCurrentId }) {

  const history = useHistory()
  const { slug } = useParams()
  const [progress, setProgress] = useState(0)
  const { specie } = useSelector((state) => state.species);
  const [postData, setPostData] = useState({ 
    name:'',
    scientificName: '',
    content: '',
    fileUrl: '',
    map: [],
    status: '',
    photoCredit: '',
  }
)


useEffect(() => {
  setProgress(75)
    dispatch(getSpecie(slug));
    setProgress(100)
    // eslint-disable-next-line
  }, [slug]);

  useEffect(() => {
      if(specie) {
          setPostData(specie)
      } else {
        clear()
      }
  },[specie])


const dispatch = useDispatch()
const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
// eslint-disable-next-line 
const [openSnackbar, closeSnackbar] = useSnackbar()

// console.log(postData)

// useEffect(() => {
//   if (post) {
//     setPostData(post);
//     // setPostData({...post, fileUrl: post.fileUrl})
//   }
// }, [post]);

useEffect(() => {
  setUser(JSON.parse(localStorage.getItem('profile')))
},[postData])

useEffect(() => {
  dispatch(getProfiles());
},[dispatch])


const handleSubmit =(e)=> {
    e.preventDefault()
    if(specie) {
      dispatch(updateSpecies(specie._id, postData, openSnackbar)) 
     
    } else {
      dispatch(createSpecies(postData, openSnackbar ))
    }
    // clear()
    // window.location.href="/profile"
    history.push('/species')
}

  const clear =() => {
    setPostData({ 
      name:'',
      scientificName: '',
      content: '',
      fileUrl: '',
      map: [],
      status: '',
      photoCredit: '',
    }
  )
}


const handleChange = (e) => setPostData({ ...postData, [e.target.name]: e.target.value });

console.log(postData)

// const isPdf = postData.fileUrl.split('.').pop()

if(!user || user.result.role !== 'admin') {
  history.push('/auth')
}

  return (

    <div className={styles.layout}>
       <LoadingBar
        color='#81b443'
        height="3px"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

    <div>
    <form >
            <Grid container spacing={2}>
             
              <Input name="name" label="Common Name" handleChange={handleChange} type="text" required={true} value={postData.name} half />
                <Input name="scientificName" label="Scientific Name" value={postData.scientificName} handleChange={handleChange} type="text" required={false} half />
                <Input name="status" label=" IUCN Status e.g (LC)" value={postData.status} handleChange={handleChange} type="text" required={false} half />
                <Input name="photoCredit" label="Photo Credit" value={postData.photoCredit} handleChange={handleChange} type="text" required={false} half />
                <Uploader setPostData={setPostData} postData={postData} />
                <MapUploader postData={postData} setPostData={setPostData} />
                <TinyMCE setPostData={setPostData} postData={postData}  />
            </Grid>

            <Divider />
            <div style={{paddingTop: '40px'}}>
              <Button autoFocus variant="contained" color="secondary" onClick={handleSubmit}>
            {specie ? 'Save' : 'Publish'}
            </Button>
            </div>

        </form>
    </div>


    </div>


  );
}