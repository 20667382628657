import React, { useEffect } from 'react'
import StaticPage from '../components/StaticPage/StaticPage'
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'

const Library = () => {


    
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
    const browserHistory = createBrowserHistory()
    browserHistory.listen((location, action) => {
      ReactGA.pageview(location.pathname + location.search)
    })
  
    useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])

    const title = "The Library"
    const image = "https://res.cloudinary.com/wabis/image/upload/v1628087161/special/APLORI_LIBRARY_1_qy460y.jpg"
    const content = "<p>The library of the A.P. Leventis Ornithological Research Institute is West Africa’s most extensive collection of ornithological literature. The library also houses documents on conservation, ecology, botany and forestry. Though its collection is used primarily by staff and students of the institute, the library is opened to all members of the university, other professional institutions and other interested members of the public.</p><p>&nbsp;</p><h2>Opening times &amp; Contact Details</h2><p>The library is open to users during the following times:</p><p>&#42;  Monday to Friday, 8:30 - 15:50. </p><p>&#42;  Closed on weekends and public holidays.</p><p>&#42;  Visits outside opening hours is available on request.</p><p>&nbsp;</p><p>Contact details</p><p>Contact person: Anthony Umenuihe</p><p>Phone no: +234 (0) 806 278 8408</p><p>Email: anthony.umennuihe@aplori.org</p><p>&nbsp;</p><h2>Borrowing</h2><p>&#42;  Borrowing is free of charge. However, users must register with the library first.</p><p>&#42;  Journals, reference books, field guides and reference copies of core publications may not be taken out unless a special arrangement is made with the library manager.</p><p>&#42;  Borrowed items may not be passed to third parties.</p><p>&#42;  Borrowed items can only be kept for a period of 1 week and can be renewed on expiration if there is no reservation by another user.</p><p>&#42;  It is the responsibility of the user to return items on time.</p><p>&nbsp;</p><h2>Code of conduct in the library</h2><p>&#42;  Eating, smoking and pets are not allowed in the library to ensure a smooth-working environment for all users.</p><p>&#42;  Library staff reserves the authority to perform random bag checks.</p><p>&#42;  Library staff reserve the right to expel users should they violate the term of use.</p><p>&nbsp;</p><h2>Fees</h2><p>Use of the library and its resources is free of charge.</p><p><br></p><p> &nbsp;</p>"

    return (
        <div>
            <StaticPage title={title} content={content} image={image} />
        </div>
    )
}

export default Library
