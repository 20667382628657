import React, { useState, useEffect} from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import styles from './Equipment.module.css'
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import { deleteEquipment } from '../../actions/equipment';


const EquipmentCard = ({ equipment, user, handleEdit, handleRequest }) => {


  const dispatch = useDispatch()
const searchQuery = {search: equipment._id}

const [lenders, setLenders] = useState([])
 useEffect(() => {

    axios.get(`https://aplori.herokuapp.com/request/equipment?searchQuery=${searchQuery.search}`)
  .then(function (response) {
    // handle success
    setLenders(response?.data?.data)
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .then(function () {
    // always executed
  });

      // eslint-disable-next-line
 }, [])



let lended = 0
for(let i = 0; i < lenders.length; i++) {
    lended += lenders[i].quantity
}

// console.log(lended, equipment)

const confirmDelete =(id) => {
  var result = window.confirm("Are you sure you Want to delete this Equipment?");
  if (result) {
    dispatch(deleteEquipment(id))
  }

}


  return (
    <li  className={styles.listItem2} key={equipment.name}>
        <img className={styles.imgThumbnail} onClick={user?.result?.isStoreAdmin ? () => handleEdit(equipment._id) : null } src={equipment.imageUrl} alt={equipment.name} />
        <div className={styles.specieInfo}>
        <div>
            <h2 style={{cursor: 'pointer', lineHeight: '15px',  fontFamily: 'Roboto', fontSize: '13px'}}>{equipment.name} <span>{user?.result?.isStoreAdmin ? `(${equipment.quantity})` : ''}</span> </h2>
            <p style={{marginTop: '-20px', fontStyle: 'italic', color: 'gray', fontFamily: 'Roboto', fontSize: '13px'}}>{`${(equipment.quantity - lended)} available`}</p>
        </div>
        {/* <div className={styles.green}>{equipment.quantity}</div> */}
        </div>
        <div className={styles.btnContainer}>
        <button className={styles.submitBtn} onClick={()=>handleRequest(equipment)}>Request</button>
        
        {user?.result?.isStoreAdmin && (
          <IconButton aria-label="delete" onClick={()=> confirmDelete(equipment._id)}>
            <DeleteOutlineRoundedIcon />
          </IconButton>
        )}
        </div>
    </li>

  )
}

export default EquipmentCard