import { FETCH_POINTCOUNTS_BY_SEARCH } from "../actions/constants";

const pointCounts =  (state = { isLoading: true, pointCounts: [] }, action)  => {
  switch (action.type) {
    case FETCH_POINTCOUNTS_BY_SEARCH:
      return { ...state, pointCounts: action.payload.data };
    default:
      return state;
  }
};

export default pointCounts