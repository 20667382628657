import React from 'react'
import Auth from '../components/Auth/Auth'
import { Link } from 'react-router-dom'


const layoutStyle = {
    padding: '120px 0px 100px 0px',
    display: 'flex',
    flexDirection: 'column'
}

const AuthPage = () => {
    return (
        <div style={layoutStyle}>
            <Auth />
            <Link to="forgot" style={{textAlign: 'center', padding: '30px'}}><span style={{textAlign: 'center', color: '#1d7dd6', marginTop: '20px', width: '100%', margin: '0 auto'}}>Forgotten Password?</span></Link>
        </div>
    )
}

export default AuthPage
