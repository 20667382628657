import React from 'react'
import { useHistory } from 'react-router-dom';
import styles from './RecentNews.module.css'
import { checkStatus } from '../../../../utils/utils';



// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }


const RecentNews = ({ species }) => {

  // const query = useQuery();
  const history = useHistory()
  // const classes = useStyles();

 
  // const [pageNumber, setPageNumber] = useState()
  // const pages = new Array(numberOfPages).fill(0).map((v, i) => i);
 

  // useEffect(() => {
  //     dispatch(getNews());
  //      // eslint-disable-next-line 
  // }, []);

  const openPost =(slug) => history.push(`/birds/${slug}`)



    return (
      <div className={styles.featuresContainer} >
            <div className={styles.paragraph}>
                {/* <h1 style={{paddingBottom: '30px'}}>Recent<span style={{color: '#68a374'}}> News</span> </h1> */}
                {/* <hr className={styles.hrLine}/> */}
                {/* <p> 
                Stay informed and up-to-date with the latest news and activities of the A.P. Leventis Ornithological Research Institute. Read our conservation news and find out how we’re working to protect our natural world
                </p> */}
            </div>

            <div className={styles.items}>
                <ul className={styles.autoGrid}>
                    {
                      species?.map((post) => (
                        <li key={post._id} className={styles.listItem}>
                        <img onClick={() => openPost(post.slug)} className={styles.imgThumbnail} src={post.fileUrl ? post.fileUrl : "https://i.postimg.cc/8zbCw3P7/APLORI-thumbnail.png"} alt={post.title} />
                        <div className={styles.specieInfo}>
                          <div>
                              <h2 style={{cursor: 'pointer'}} onClick={() => openPost(post.slug)}>{post.name}</h2>
                              <p style={{marginTop: '-25px', fontStyle: 'italic', color: 'gray', fontSize: '14px'}}>{post.scientificName}</p>
                          </div>
                          <div className={checkStatus(post.status)}>{post.status}</div>
                        </div>
                    </li>
                      ))
                    }
                </ul>

                <div className={styles.loadMore}>
                <button className={styles.loadMoreBtn}
                  onClick={()=> history.push('/birdlist')} style={{}}>
                     Load More
                </button>
                </div>
            </div>

        </div>
    )
}

export default RecentNews
