/* eslint-disable */
import React, {useEffect} from 'react'
import styles from './Events.module.css'

import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'

const Events = () => {

    
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
  const browserHistory = createBrowserHistory()
  browserHistory.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search)
  })

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  
    return (
        <div className={styles.layout} >
            <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Africa%2FLagos&src=YXBsb3JpLmV2ZW50c0BnbWFpbC5jb20&color=%23039BE5&showTitle=0&showDate=1" style={{borderWidth:0}} width="1200" height="600" frameborder="0" scrolling="no"></iframe>
        </div>
    )
}

export default Events
