import { 
    START_LOADING,
    END_LOADING,
    FETCH_POINTCOUNTS_BY_SEARCH,
  } from './constants';
  import * as api from '../api/index.js';
  

  // export const getPointCountsBySearch = (searchQuery) => async (dispatch) => {
  //   try {
  //     dispatch({ type: START_LOADING });
  //     const { data } = await api.fetchPointCountsBySearch(searchQuery);
  
  //     dispatch({ type: FETCH_POINTCOUNTS_BY_SEARCH, payload: data });
  //     dispatch({ type: END_LOADING });
  //   } catch (error) {
  //     console.log(error.response);
  //   }
  // };
  

  export const getPoinAbunanceBySearch = (searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.fetchPointAbundanceBySearch(searchQuery);
  
      dispatch({ type: FETCH_POINTCOUNTS_BY_SEARCH, payload: data });
      dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error.response);
    }
  };


  export const getArrivalAndDepature = (searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.fetchArrivalAndDepature(searchQuery);
  
      dispatch({ type: FETCH_POINTCOUNTS_BY_SEARCH, payload: data });
      dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error.response);
    }
  };



//   export const getPosts = () => async (dispatch) => {
//     try {
//       const { data } = await api.fetchPosts();
  
//       dispatch({ type: FETCH_ALL, payload: data });
//     } catch (error) {
//       console.log(error);
//     }
//   };
  