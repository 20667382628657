import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function MaterialUIPickers({requestData, setRequestData}) {

  const user = JSON.parse(localStorage.getItem('profile'))
 
  const handleDateApproved = (date) => {
    setRequestData({...requestData, approvedDate: date});
  };

  const handleDueDate = (date) => {
    setRequestData({...requestData, dueDate: date});
  }

  const handleDateReturned = (date) => {
    setRequestData({...requestData, returnedDate: date});
}

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justifycontent="space-around">
        <KeyboardDatePicker
        style={{padding: '0 10px'}}
        fullWidth
          margin="normal"
          id="date-picker-dialog"
          label="To be returned on"
          format="MM/dd/yyyy"
          value={requestData.dueDate}
          onChange={handleDueDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />

       {user?.result?.isStoreAdmin && (
         <>
          <KeyboardDatePicker
          style={{padding: '0 10px'}}
          margin="normal"
          id="date-picker-dialog"
          label="Date Approved"
          format="MM/dd/yyyy"
          value={requestData.approvedDate}
          onChange={handleDateApproved}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />

        <KeyboardDatePicker
          style={{padding: '0 10px'}}
          margin="normal"
          id="date-picker-dialog"
          label="Date Returned"
          format="MM/dd/yyyy"
          value={requestData.returnedDate}
          onChange={handleDateReturned}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
         </>
       )}

      </Grid>
    </MuiPickersUtilsProvider>
  );
}
