import React from 'react'
import styles from './Sponsors.module.css'


const CitizenScience = () => {

    const sponsors = [
        {name: 'University of Jos', url: 'https://unijos.edu.ng', image: 'https://res.cloudinary.com/wabis/image/upload/v1637745131/special/sponsors/Unijos_a3bjl4.png'},
        {name: 'University of St. Andrews', url: 'https://www.st-andrews.ac.uk/', image: 'https://res.cloudinary.com/wabis/image/upload/v1637745131/special/sponsors/st-andrews_thlh4b.png'},
        {name: 'Leventis Foundation', url: 'https://leventisfoundation.org.ng/', image: 'https://res.cloudinary.com/wabis/image/upload/v1637745131/special/sponsors/Leventis-Foundation_hbnczb.png'},
        {name: 'Fitz', url: 'http://www.fitzpatrick.uct.ac.za/', image: 'https://res.cloudinary.com/wabis/image/upload/v1637745129/special/sponsors/fitz_jwqekj.png'},
        {name: 'Swiss Orni', url: 'https://www.vogelwarte.ch/en/home/', image: 'https://res.cloudinary.com/wabis/image/upload/v1637745130/special/sponsors/Swiss-Institute_y0xykr.png'},
        {name: 'Cape Coast', url: 'https://ucc.edu.gh', image: 'https://res.cloudinary.com/wabis/image/upload/v1637745130/special/sponsors/cape_coast_ctlh3g.png'},
        {name: 'RSPB', url: 'https://www.rspb.org.uk/', image: 'https://res.cloudinary.com/wabis/image/upload/v1637745130/special/sponsors/rspb_m4jjbv.png'},
        {name: 'Birdlife Int', url: 'http://www.birdlife.org/', image: 'https://res.cloudinary.com/wabis/image/upload/v1637745129/special/sponsors/bird-International_zibbtp.png'},
        {name: 'Birdlife Sweden', url: 'https://birdlife.se/', image: 'https://res.cloudinary.com/wabis/image/upload/v1637745129/special/sponsors/birdlife-sweden_fe9frj.png'},
        {name: 'BDI', url: 'http://thebdi.org/', image: 'https://res.cloudinary.com/wabis/image/upload/v1637745128/special/sponsors/bdi_bb3od7.png'},
        {name: 'BTO', url: 'https://bto.org/', image: 'https://res.cloudinary.com/wabis/image/upload/v1637745129/special/sponsors/bto_ppvviy.png'},
        {name: 'NCF', url: 'https://ncfnigeria.org/contact-ncf/', image: 'https://res.cloudinary.com/wabis/image/upload/v1637745129/special/sponsors/ncf_gz3uvb.png'},
        {name: 'Birdlife Netherlands', url: 'https://www.birdlife.org/partners/netherlands-vogelbescherming-nederland-vbn/', image: 'https://res.cloudinary.com/wabis/image/upload/v1637745128/special/sponsors/birdlife-netherlands_wivpu4.png'},
        {name: 'IITA', url: 'https://www.iita.org/', image: 'https://res.cloudinary.com/wabis/image/upload/v1638561912/special/sponsors/IITA_injtvx.png'},
        {name: 'EDEN Creations', url: 'https://nigeria.arocha.org/', image: 'https://res.cloudinary.com/wabis/image/upload/v1638561913/special/sponsors/EDEN_htqd9f.png'},
        {name: 'SCERA Nigeria', url: 'http://scera-ng.org/', image: 'https://res.cloudinary.com/wabis/image/upload/v1638561913/special/sponsors/Scera_ccoxkd.png'},
    ]

    return (
      <div className={styles.featuresContainer} >
            <div className={styles.items}>
                <ul className={styles.autoGrid}>
                    {sponsors.map((sponsor) => (
                        <a href={sponsor.url} target="blank" noreferrer>
                        <li className={styles.listItem} key={sponsor.name}>
                        <img className={styles.imgThumbnail} src={sponsor.image} alt={sponsor.name} />
                    </li>
                    </a>
                    ))}
                </ul>
            </div>


        </div>
    )
}

export default CitizenScience
