/* eslint-disable */
//OLD EQUIPMENT CONFIG WITH DYNAMIC CALCULATIONS FROM THE DATABASE
import React, { useState, useEffect} from 'react'
import RequestList from './RequestList'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import styles from './Equipment.module.css'
import { useSnackbar } from 'react-simple-snackbar'
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NewRequest from './NewRequest'
import NewEquipment from './NewEquipment'
import { getRequests } from '../../actions/request'
import { getEquipments, getEquipmentsBySearch } from '../../actions/equipment'
import { CSVLink } from "react-csv";

import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'
import EquipmentCard from './EquipmentCard'

 const Equipment = () => {


  //var filtered = someArray.filter(function(el) { return el.Name != "Kristian"; }); 

  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
  const browserHistory = createBrowserHistory()
  browserHistory.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search)
  })

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])
  
 
     const history = useHistory()
     const location = useLocation()
     const [open, setOpen] = useState(false)
     const [currentId, setCurrentId] = useState(null)
     const dispatch = useDispatch()
     const user = JSON.parse(localStorage.getItem('profile'))
     const [pageNumber, setPageNumber] = useState()
     const { requests } = useSelector((state) => state.requests)
     const { equipments, numberOfPages } = useSelector((state) => state.equipments)
     const pages = new Array(numberOfPages).fill(0).map((v, i) => i);

     console.log(requests)
     
     const gotoPrevious = () => {
        setPageNumber(Math.max(0, pageNumber - 1));
      }
    
      const gotoNext = () => {
        setPageNumber(Math.min(numberOfPages - 1, pageNumber + 1));
      };

   


    //  for(let i = 0; i < requests.length; i++) {
    //     for(let j = 0; j < equipments.length; j++) {
    //         if((requests[i].requestId === equipments[j]._id) && (requests[i].status === "received")) {
    //             equipments[j].borrowed += requests[i].quantity
    //         }
    //     }
    // }
    
    const [openEquipment, setOpenEquipment] = useState(false)
    const [currentEquipment, setCurrentEquipment] = useState(null)
    const equipment = useSelector((state)=> currentEquipment ? state.equipments.equipments.find((c) => c._id === currentEquipment) : null)
    const handleEdit = (id) => {
        setOpenEquipment((prevState) => !prevState)
        setCurrentEquipment(id)
      }

      useEffect(() => {
        if(equipment) {
            setEquipmentData(equipment)
        }
    }, [equipment])

     // eslint-disable-next-line 
     const [openSnackbar, closeSnackbar] = useSnackbar()
    const today = new Date();

     const [requestData, setRequestData] = useState({ 
      name: '', 
      email: '', 
      phone: '', 
      userId: '',
      type: '',
      equipment: '',
      requestId: '',
      quantity: 0,
      reason: '',
      note: '',
      status: 'pending',
      requestDate: new Date(),
      approvedDate: null,
      dueDate: today.getTime() + 60 * 24 * 60 * 60 * 1000,
      returnedDate: null,

      })

      const [equipmentData, setEquipmentData] = useState({ 
        name: '', 
        quantity: '', 
        borrowed: 0,
        imageUrl: '',
        userId: [],
        })



        // console.log(equipmentData)
// React CSV
const requestHeaders = [
  { label: "name", key: "name" },
  { label: "email", key: "email" },
  { label: "phone", key: "phone" },
  { label: "equipment", key: "equipment" },
  { label: "quantity", key: "quantity" },
  { label: "issuedBy", key: "issuedBy" },
  { label: "requestDate", key: "requestDate" },
  { label: "status", key: "status" },
  
];

const requestReport = {
  data: requests,
  headers: requestHeaders,
  filename: 'requests.csv'
}



// React CSV
const equipmentHeaders = [
  { label: "name", key: "name" },
  { label: "quantity", key: "quantity" },
]

const equipmentReport = {
  data: equipments,
  headers: equipmentHeaders,
  filename: 'equipment.csv'
}


const [search, setSearch] = useState('');
const [year, setYear] = useState('');
const searchPost = () => {
    if (search.trim() || year.trim() ) {
      dispatch(getEquipmentsBySearch({ search }));
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchPost();
    }
  };


     const handleRequest = (equipment) => {
        setRequestData({
            ...requestData, 
            name: user.result.name, 
            email: user.result.email,
            userId: user?.result?._id,
            equipment: equipment.name,
            requestId: equipment._id
        })
         setOpen((prev) => !prev)
     }

     useEffect(() => {
      dispatch(getRequests(pageNumber));
      dispatch(getEquipments(pageNumber));
      // eslint-disable-next-line
  }, [pageNumber, location]);

   if(!user) {
     history.push('/auth')
   }
 

     return (
         <div className={styles.layout}>
           <section className={styles.intro}>
               <div>
                    <h1 style={{padding: '10px'}}>Lend equipment from the Institute</h1>
                    <p>
                        To ensure the continuity of the Institute’s effort to support community members as 
                        they engage in carrying out conservation research, the Institute have a range of 
                        research equipment to lend to qualified individuals. Below, you you’ll find a list 
                        of available items and their quantity.
                    </p>
                </div>
                <div className={styles.searchBar}>
                    <input type="text" onKeyDown={handleKeyPress} onChange={(e) => setSearch(e.target.value)} placeholder="search equipment" />
                    <button onClick={searchPost} >Search</button>
                </div>
           </section>

           <NewEquipment 
            open={openEquipment} 
            setOpen={setOpenEquipment}
            currentEquipment={currentEquipment}
            setCurrentEquipment={setCurrentEquipment}
            equipmentData={equipmentData}
            setEquipmentData={setEquipmentData}
            openSnackbar={openSnackbar}
           
           />

            {user?.result?.isStoreAdmin && (
                <div  className={styles.addBtnContainer}>
                <button className={styles.button} onClick={() => setOpenEquipment((prev) => !prev)}>New Equipment</button>
                <button className={styles.button}>  <CSVLink {...equipmentReport}>Export Equipment</CSVLink> </button>
                <button className={styles.button}>  <CSVLink {...requestReport}>Export Requests</CSVLink> </button>
            </div>
            )}

           <section className={styles.equipmentList}>
                <ul className={styles.autoGrid2}>
                    {
                        equipments.map((equipment) => (
                          <EquipmentCard 
                            equipment={equipment} 
                            user={user} 
                            key={equipment._id} 
                            handleEdit={handleEdit}
                            handleRequest={handleRequest}
                          />
                        ))
                    }
                  

                </ul>

            <div className={styles.pagination}>
              <IconButton onClick={gotoPrevious}>
                <FirstPageIcon />
              </IconButton>
              {pages.map((pageIndex) => (
                <button className={`${styles.pageNum}` } key={pageIndex} onClick={() => setPageNumber(pageIndex)}>
                  {pageIndex + 1}
                </button>
              ))}
              <IconButton onClick={gotoNext}>
                <LastPageIcon />
              </IconButton>
              </div>

            </section>

             <div>
             <NewRequest
                 open={open} 
                 setOpen={setOpen}
                 currentId={currentId}
                 setCurrentId={setCurrentId}
                 requestData={requestData}
                 setRequestData={setRequestData}
             />
             {requests.length !== 0 && (
                 <RequestList
                 open={open} 
                 setOpen={setOpen}
                 currentId={currentId}
                 setCurrentId={setCurrentId}
                requests={requests}
             />
             )}
            </div>

         </div>
         
     )
 }
 
 export default Equipment
