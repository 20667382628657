import React from "react";


export const Send = () => {
  return (
    <svg
    style={{color: 'orange', margin: '20px'}}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="feather feather-send"
      viewBox="0 0 24 24"
    >
      <path d="M22 2L11 13"></path>
      <path d="M22 2L15 22 11 13 2 9 22 2z"></path>
    </svg>
  );
}


export const Book = () =>  {
  return (
    <svg
    style={{color: '#3e6947', margin: '20px'}}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="feather feather-book-open"
      viewBox="0 0 24 24"
    >
      <path d="M2 3h6a4 4 0 014 4v14a3 3 0 00-3-3H2zM22 3h-6a4 4 0 00-4 4v14a3 3 0 013-3h7z"></path>
    </svg>
  );
}



export const CloseBook = () => {
  return (
    <svg
    style={{color: '#3e6947', margin: '20px'}}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="feather feather-book"
      viewBox="0 0 24 24"
    >
      <path d="M4 19.5A2.5 2.5 0 016.5 17H20"></path>
      <path d="M6.5 2H20v20H6.5A2.5 2.5 0 014 19.5v-15A2.5 2.5 0 016.5 2z"></path>
    </svg>
  );
}



export const Feather = () => {
  return (
    <svg
    style={{color: '#81b443', margin: '20px'}}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="feather feather-feather"
      viewBox="0 0 24 24"
    >
      <path d="M20.24 12.24a6 6 0 00-8.49-8.49L5 10.5V19h8.5z"></path>
      <path d="M16 8L2 22"></path>
      <path d="M17.5 15L9 15"></path>
    </svg>
  );
}



export const Calender = () => {
  return (
    <svg
    style={{color: '#81b443', margin: '20px'}}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="feather feather-calendar"
      viewBox="0 0 24 24"
    >
      <rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect>
      <path d="M16 2L16 6"></path>
      <path d="M8 2L8 6"></path>
      <path d="M3 10L21 10"></path>
    </svg>
  );
}

