import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './SpeciesDetail.module.css'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Spinner from '../../../components/Spinner/Spinner'
import ReactShare from '../../../components/ReactShare'

import parser from 'html-react-parser'
import { getSpecie } from '../../../actions/species'
import { FingerPrint } from './Icons'

import { checkStatus } from '../../../utils/utils'

const PostDetails = () => {
  
    const { specie, isLoading } = useSelector((state) => state.species)
    const dispatch = useDispatch()
    const { slug } = useParams()

    // eslint-disable-next-line
    const [open, setOpen] = useState(false);
    
    // const bigIcons = {
    //     color: "#d3fca4",
    //     textAlign: "center",
    //     fontSize: "26px",
    //     position: "absolute",
    //     top: "50%",
    //     let: "50%",
    //     margin: "-15px 0 0 10px"
    // } 


    useEffect(() => {
        dispatch(getSpecie(slug))
        // eslint-disable-next-line 
    }, [slug])

    if(!specie) return null

    if(isLoading) {
        return <Spinner />
    }


    const changeMapUrl = () => {
        setOpen(true)

    }

    return (
        <div>

            <div>
                <section className={styles.birdInfo}>
                    <div className={styles.birdStat}>
                        <div className={checkStatus(specie.status)}>{specie.status}</div>
                        <h1 className={styles.comName} >{specie.name}</h1>
                        <h4 className={styles.sciName}>{specie.scientificName}</h4>
                        {/* <div className={styles.identification}> {parser(specie.content.split(' ').splice(0, 50).join(' '))}</div> */}
                        
                    </div>

    
                        <img className={styles.birdImg} src={(specie.fileUrl ? specie.fileUrl : "https://i.postimg.cc/8zbCw3P7/APLORI-thumbnail.png")} alt={specie.name} />
                </section>

                {specie.photoCredit && (
                    <div style={{ float: 'right', padding: '4px', marginTop: '-36px', position: 'relative', zIndex: 10000, backgroundColor: '#ffffff' }}>
                        <p style={{fontSize: '13px'}}>©{specie.photoCredit}</p>
                    </div>
                )}

                <section className={styles.generalInfo}>
                <div className={styles.content}>

                    <div className={styles.details}>

                        <div className={styles.topic}>
                            <div className={styles.iconBox}><FingerPrint /></div>
                            <h2>General Information</h2>
                        </div>
                        <div>{parser(specie.content)}</div>


                        {/* <div className={styles.topic}>
                            <div className={styles.iconBox}> <Distribution /> </div>
                            <h2>Distribution</h2>
                        </div>
                        <div>{parser(specie.distribution)}</div>


                        <div className={styles.topic}>
                            <div className={styles.iconBox}><Forest /> </div>
                            <h2>Habitat</h2>
                        </div>
                        <p>{parser(specie.habitat)}</p>
                        

                        <div className={styles.topic}>
                            <div className={styles.iconBox}> <Feeding /></div>
                            <h2>Feeding</h2>
                        </div>
                        <div>{parser(specie.diet)}</div>

                        
                        <div className={styles.topic}>
                            <div className={styles.iconBox}> <Breeding /> </div>
                            <h2>Breeding</h2>
                        </div>
                        <div>{parser(specie.breeding)}</div>

                    </div>

                    <div className={styles.topic}>
                            <div ></div>
                            <h2>References</h2>
                        </div>
                        <div>{parser(specie?.reference)}</div> */}

                </div>
                </div>

                <div className={styles.map}>
                        <div>
                            {specie?.map?.map((url) => (
                                 <img  className={styles.maps} key={url} src={url} alt={specie.name} onClick={(url)=> changeMapUrl(url)}/>
                            ))}
                        </div>
                        {/* <div>
                            <img src={specie.map[1]} style={{width: '450px', cursor: 'pointer'}} onClick={(url)=> changeMapUrl(url)}/>
                            <Map0 open={open} setOpen={setOpen} mapUrl={specie.map[1]} specie={specie}/>
                        </div> */}

                    
                </div>
                </section>


            </div>

        <div style={{paddingLeft: '100px'}}>
            <ReactShare url={`https://aplori.org/birds/${specie.slug}`} title={specie.title}/>
        </div>
        </div>


    )
}

export default PostDetails
