import {combineReducers } from 'redux'
import posts from './posts'
import auth from './auth'
import profiles from './profiles'
import species from './species'
import requests from './request'
import equipments from './equipment'
import pointCounts from './pointCounts'

export default combineReducers({ 
    posts, 
    auth, 
    profiles, 
    species, 
    equipments, 
    requests, 
    pointCounts 
})