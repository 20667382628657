import React, { useState, useEffect } from 'react'
import LoadingBar from 'react-top-loading-bar'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import styles from './SpeciesBoard.module.css'
import { getSpecies, getSpecie, deleteSpecies, getSpeciesBySearch } from '../../../actions/species'
import { getProfiles } from '../../../actions/profile';
// import AddIcon from '@material-ui/icons/Add';
// import AddPost from './AddPost/AddPost';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
// import TinyMCE from './AddPost/TinyMCE'
// import Editor from './AddPost/Editor';
// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const SpeciesBoard = () => {

  // const [open, setOpen] = useState(false)
  // const [currentId, setCurrentId] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const [search, setSearch] = useState('');
  const user = JSON.parse(localStorage.getItem('profile'))
  const [progress, setProgress] = useState(0)
  let year = ""

  const { numberOfPages } = useSelector((state) => state.species);
  const { species } = useSelector((state) => state.species);
  const [pageNumber, setPageNumber] = useState()
  const pages = new Array(numberOfPages).fill(0).map((v, i) => i);



  //CONTROL HOW THE EDIT WORKS
  //EDIT IN A NEW PAGE (Change ID to slug from the edit button)
  //EDIT IN A MODAL (change slug to id from the edit button)

  const handleEdit = (id) => {
    
    // edit in a new page
    setProgress(25)
    dispatch(getSpecie(id))
    .then(() => history.push(`/edit/species/${id}`))
    .catch((error) => console.log(error.message))
    setProgress(50)

    //edit in modal
  //   dispatch(getSpecie(id))
  //  setCurrentId(id)
  //  setOpen(true)

  }
 

  useEffect(() => {
      dispatch(getSpecies(pageNumber));
      dispatch(getProfiles())
      // eslint-disable-next-line
  }, [pageNumber, dispatch]);

  const gotoPrevious = () => {
    setPageNumber(Math.max(0, pageNumber - 1));
  };

  const gotoNext = () => {
    setPageNumber(Math.min(numberOfPages - 1, pageNumber + 1));
  };

  const searchPost = () => {
    if (search.trim() || year.trim() ) {
      dispatch(getSpeciesBySearch({ search, year }));
      // history.push(`/posts/search?searchQuery=${search || 'none'}&tags=${tags.join(',')}`);
    } else {
      history.push('/');
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchPost();
    }
  };
  


  // useEffect(() => {
  //   if(message) {
  //     openSnackbar('Post added successfully')
  //   }
  // }, [])

      // if(!posts) return null

      // if(isLoading) {
      //     return <Spinner />
      // }


      if((!user) || user.result.role !== 'admin') {
        history.push('/')
      }
  
    return (
      <>
        <LoadingBar
        color='#81b443'
        height="3px"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
        <div className={styles.layout}>
            <div className={styles.header}>
              {/* <TinyMCE /> */}
             
                <h1 style={{padding: '20px'}}>Manage Species</h1>
                {/* <p>Search our Journal repository for peer-reviewed, scientific publications produced by staff and students of the Institute. The lists include all publications produced since the Institute's inception</p> */}
                <div className={styles.searchBar}>
                <input type="text" onKeyDown={handleKeyPress} onChange={(e) => setSearch(e.target.value)} placeholder="Type and press enter" />
                <button onClick={searchPost} >Search</button>
                </div>
                {/* <div className={styles.btnContainer}>
                  <button onClick={() => setOpen((prevState) => !prevState)} className={styles.editButton}><AddIcon />Create Post</button>
                </div> */}
                <div className={styles.buttons}>
              
           </div>
                <button className={styles.newBtn} onClick={()=>history.push('/newspecies')}>Add New Species</button>
                {/* <AddPost open={open} setOpen={setOpen} currentId={currentId} setCurrentId={setCurrentId} /> */}
            </div>

            <div className={styles.cardContainer}>


<div className={styles.featuresContainer} >

<div className={styles.items}>
    <ul className={styles.autoGrid}>
       {
           species.map((specie) => (
              
                <li className={styles.listItem} key={specie.name}>
                    <div className={styles.imgContainer}>
                        <img  className={styles.imgThumbnail} src={specie.fileUrl ? specie.fileUrl : "https://i.postimg.cc/8zbCw3P7/APLORI-thumbnail.png"} alt="" />
                    </div>
                    <div className={styles.text} >
                        <h2 style={{paddingBottom: '10px'}}>{specie.name}</h2>
                        <p style={{fontStyle: 'italic', marginTop: '-10px'}}>{specie?.scientificName}</p>
                    </div>
                    <div>
                        <IconButton  onClick={() => {handleEdit(specie.slug)}}>
                            <EditIcon style={{height: '15px', width: '15px'}} />
                        </IconButton>
                        <IconButton onClick={() => dispatch(deleteSpecies(specie._id))}>
                            <DeleteOutlineIcon style={{height: '15px', width: '15px'}}  />
                        </IconButton>
                    </div>
                </li>
          
           ))
       }
        
    </ul>

</div>

</div>





            </div>
            <div className={styles.pagination}>
              <IconButton onClick={gotoPrevious}>
                <FirstPageIcon />
              </IconButton>
              {pages.map((pageIndex) => (
                <button className={`${styles.pageNum}` } key={pageIndex} onClick={() => setPageNumber(pageIndex)}>
                  {pageIndex + 1}
                </button>
              ))}
              <IconButton onClick={gotoNext}>
                <LastPageIcon />
              </IconButton>
             
            </div>

        </div>
      </>
    )
}

export default SpeciesBoard
