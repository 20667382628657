import React from 'react'
import styles from './Coverage.module.css'
import Table from './Table'


const Coverage = () => {


    return (
      <div className={styles.featuresContainer} >
            <div className={styles.items}>
                <ul className={styles.autoGrid}>

                    <li className={styles.listItem}>
                        <img  className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1706861347/aplori/2023_fk90z2.png" alt="Nibap bird map coverage" />
                        <h2 style={{cursor: 'pointer', textAlign: 'center', fontSize: '23px'}} >Coverage as at December 2023</h2>
                    </li>

                    <li className={styles.listItem}>
                        <div>
                        <ul style={{padding: '30px'}}>
                        <h2 style={{cursor: 'pointer', textAlign: 'left', fontSize: '23px'}} >Developing Capacity for citizen science in West Africa</h2>
                            <li><p>From a small team of <strong style={{color: '#3e6947'}}>3 in 2015 to 250+ active contributors/volunteers in 2023 </strong></p></li>
                            <li><p>From  <strong style={{color: '#3e6947'}}> two bird clubs prior to this project to ~30 bird clubs in Nigeria in 2023  </strong></p></li>
                            <li><p>From  <strong style={{color: '#3e6947'}}>7 in 2015 to greater than 3,600 pentads covered in 2023  </strong></p></li>
                            <li><p> Online community close to 5000 persons</p></li>
                            </ul>

                            <div style={{padding: '0px 20px'}}>
                                <Table />
                            </div>
                        </div>
                        
                    </li>

                </ul>
               
            </div>


        </div>
    )
}

export default Coverage
