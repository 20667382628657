import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';


// TinyMCE so the global var exists
  // eslint-disable-next-line no-unused-vars
  import tinymce from 'tinymce/tinymce';

  // Theme
  import 'tinymce/themes/silver';
  // Toolbar icons
  import 'tinymce/icons/default';
  // Editor styles
  import 'tinymce/skins/ui/oxide/skin.min.css';

  // importing the plugin js.
  import 'tinymce/plugins/advlist';
  import 'tinymce/plugins/autolink';
  import 'tinymce/plugins/link';
  import 'tinymce/plugins/image';
  import 'tinymce/plugins/lists';
  import 'tinymce/plugins/charmap';
  import 'tinymce/plugins/hr';
  import 'tinymce/plugins/anchor';
  import 'tinymce/plugins/spellchecker';
  import 'tinymce/plugins/searchreplace';
  import 'tinymce/plugins/wordcount';
  import 'tinymce/plugins/code';
  import 'tinymce/plugins/fullscreen';
  import 'tinymce/plugins/insertdatetime';
  import 'tinymce/plugins/media';
  import 'tinymce/plugins/nonbreaking';
  import 'tinymce/plugins/table';
  import 'tinymce/plugins/template';
  import 'tinymce/plugins/help';

  import { plugins, toolbar, contentType } from './tinyConfig';



export default function App({ postData, setPostData }) {
  const editorRef = useRef(null);
  // const log = () => {
  //   if (editorRef.current) {
  //     console.log(editorRef.current.getContent());
  //   }
  // };
  
  
  return (
    <div style={{padding: '20px 0 0px 0', width: '100%'}}>
      <Editor
        // apiKey='955ug8wgwbleyds55yem1a8xrcm3ln6xbs76hvj1hxhzvyv6'
        onInit={(evt, editor) => editorRef.current = editor}
        value={postData.content}
        onEditorChange={() => setPostData({...postData, content: editorRef.current.getContent()})}
        init={{
          selector: 'textarea', 
          branding: false,
          placeholder: "Ask a question or post an",
          min_height: '200',
          width: '100%',
          menubar: false,
          'custom-deep': { inline: 'span', classes: 'myclass', deep: false },
          paste_data_images: true,
          plugins: plugins,
          toolbar: toolbar,
          content_style: contentType,

          file_picker_callback: function(cb, value, meta) {
            var input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
        
            input.onchange = function() {
              var file = this.files[0];
              var reader = new FileReader();
              
              reader.onload = function () {
                var id = 'blobid' + (new Date()).getTime();
                var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                var base64 = reader.result.split(',')[1];
                var blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);
        
                // call the callback and populate the Title field with the file name
                cb(blobInfo.blobUri(), { title: file.name });
              };
              reader.readAsDataURL(file);
            };
            
            input.click();
          }
          
        }}
      />
    </div>
  );
}
