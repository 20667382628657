import React from 'react'
// import { Link } from 'react-router-dom'
import styles from './ArrivalDepature.module.css'
import { Calender, Feather} from './icons'
// import Spinner from './Spinner/Spinner'

const Destination = ({ merge }) => {

  
    return (
       
        <div className={styles.featuresContainer} >
            <div className={styles.items}>
                <ul className={styles.autoGrid}>


                <li className={styles.listItem} style={{backgroundColor: '#3e6947'}}>
                        <div className={styles.svg}>
                         <Feather />
                        </div>
                        <p style={{fontSize: '20px', fontWeight: '700', color: 'white'}}>{merge[0]?.englishName === 'undefined' ? 'No species selected' : merge[0]?.englishName}</p>
                        <h2 style={{color: 'white', fontSize: '16px', lineHeight: '22px', padding: '5px 25px'}}>Species</h2>
                    </li>


                    <li className={styles.listItem}  style={{backgroundColor: '#eeffdb'}}>
                        <div className={styles.svg}>
                            <Calender />
                        </div>
                        <p style={{fontSize: '20px', fontWeight: '700'}}>{merge[1]?.lastSeen}</p>
                            <h2 style={{color: '#565656', fontSize: '16px', lineHeight: '22px', padding: '5px 25px'}}>Initial Seen</h2>
                    </li>


                    <li className={styles.listItem}  style={{backgroundColor: '#eeffdb'}}>
                        <div className={styles.svg}>
                            <Calender />
                        </div>
                        <p style={{fontSize: '20px', fontWeight: '700'}}>{merge[0]?.initialSeen}</p>
                            <h2 style={{color: '#565656', fontSize: '16px', lineHeight: '22px', padding: '5px 25px'}}>Last Seen</h2>
                    </li>
                    

                    {/* <li className={styles.listItem}  style={{backgroundColor: '#f7ffef'}} >
                        <div className={styles.svg}>
                          <Book />
                        </div>
                        <p>24%</p>
                            <h2 style={{color: 'black', fontSize: '16px', lineHeight: '22px', padding: '5px 25px'}} >At different stages of their PhDs</h2>
                    </li> */}


                    
                </ul>
            </div>
        </div>
    )
}

export default Destination