import React from 'react'
import { useHistory } from 'react-router-dom';
import Spinner from '../../components/Spinner/Spinner'
import styles from './RecentNews.module.css'
import moment from 'moment'
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));


const RecentNews = ({ posts }) => {


  const history = useHistory()
  const classes = useStyles();

  const openPost =(slug) => history.push(`/news/${slug}`)
  const openUser =(slug) => history.push(`/member/${slug}`)

      // if(!posts) return null

      if(posts.length === 0) {
          return <Spinner />
      }
  

    return (
      <div className={styles.featuresContainer} >
            <div className={styles.paragraph}>
                <h1 style={{paddingBottom: '30px', fontSize: '60px', paddingTop: '20px', lineHeight: '65px'}}>Recent<span style={{color: '#68a374'}}> News</span> </h1>
                {/* <hr className={styles.hrLine}/> */}
                <p> 
                Stay informed and up-to-date with the latest news and activities of the A.P. Leventis Ornithological Research Institute. Read our conservation news and find out how we’re working to protect our natural world
                </p>
            </div>

            <div className={styles.items}>
                <ul className={styles.autoGrid}>
                    {
                      posts?.slice(0, 9).map((post) => (
                        <li key={post._id} className={styles.listItem}>
                        <img onClick={() => openPost(post.slug)} className={styles.imgThumbnail} src={post.fileUrl} alt={post.title} />
                        <h2 style={{cursor: 'pointer'}} onClick={() => openPost(post.slug)}>{post.title}</h2>
                        <div className={styles.authorAndDate}>
                            <div className={styles.author} style={{cursor: 'pointer'}}  onClick={() => openUser(post.userSlug)} >
                              <Avatar className={classes.small}  alt={post.author} src={post.avatar} />
                              <h3 style={{marginLeft: '5px'}}>{post.author}</h3>
                            </div>
                            <p style={{color: 'gray', fontSize: '10px', lineHeight: '10px'}}>{moment(post.createdAt).fromNow()}</p>
                        </div>
                    </li>
                      ))
                    }
                </ul>

                <div className={styles.loadMore}>
                <button className={styles.loadMoreBtn}
                  onClick={()=> history.push('/news')} style={{}}>
                      Read more News
                </button>
                </div>
            </div>

        </div>
    )
}

export default RecentNews
