import React, { useEffect } from 'react'
import Contact from '../components/Contact/Contact'
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'



const layoutStyle = {
    padding: '120px 0px 100px 0px'
}

const ContactPage = () => {


    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
    const browserHistory = createBrowserHistory()
    browserHistory.listen((location, action) => {
      ReactGA.pageview(location.pathname + location.search)
    })
  
    useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])


    return (
        <div style={layoutStyle}>
            <Contact />
        </div>
    )
}

export default ContactPage
