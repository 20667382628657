 /* eslint-disable */
 import React, { useState, useEffect} from 'react';
 import { useLocation } from 'react-router-dom';
 import { withStyles } from '@material-ui/core/styles';
 import Button from '@material-ui/core/Button';
 import Dialog from '@material-ui/core/Dialog';
 import MuiDialogTitle from '@material-ui/core/DialogTitle';
 import MuiDialogContent from '@material-ui/core/DialogContent';
 import MuiDialogActions from '@material-ui/core/DialogActions';
 import IconButton from '@material-ui/core/IconButton';
 import CloseIcon from '@material-ui/icons/Close';
 import Typography from '@material-ui/core/Typography';
 
 import { useDispatch, useSelector } from 'react-redux'
 import { createRequest, updateRequest } from '../../actions/request'

 import PostType from './Status/Status'
 import DatePicker from './DatePicker/DatePicker'
 
 const styles = (theme) => ({
   root: {
     margin: 0,
     padding: theme.spacing(2),
     backgroundColor: '#3e6947',
     marginLeft: 0,
   },
   closeButton: {
     position: 'absolute',
     right: theme.spacing(1),
     top: theme.spacing(1),
     color: 'white',
   },
 });
 
 const DialogTitle = withStyles(styles)((props) => {
   const { children, classes, onClose, ...other } = props;
   return (
     <MuiDialogTitle disableTypography className={classes.root} {...other}>
       <Typography variant="h6">{children}</Typography>
       {onClose ? (
         <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
           <CloseIcon />
         </IconButton>
       ) : null}
     </MuiDialogTitle>
   );
 });
 
 const DialogContent = withStyles((theme) => ({
   root: {
     padding: theme.spacing(3),
   },
 }))(MuiDialogContent);
 
 const DialogActions = withStyles((theme) => ({
   root: {
     margin: 0,
     padding: theme.spacing(1),
   },
 }))(MuiDialogActions);
 
 const NewRequest = ({ setOpen, open, currentId, setCurrentId, requestData, setRequestData,openSnackbar }) => {
     const location = useLocation()
     


     const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
     const dispatch = useDispatch()
     const request = useSelector((state)=> currentId ? state.requests.requests.find((c) => c._id === currentId) : null)
 
     const handleChange = (e) => setRequestData({ ...requestData, [e.target.name]: e.target.value });
 
     useEffect(() => {
       if(request) {
         setRequestData(request)
       }
     }, [request])
 
     useEffect(() => {
       setUser(JSON.parse(localStorage.getItem('profile')))
       // setRequestData({...requestData, userId: user?.result?._id})
     },[location])


     useEffect(() => {
      setRequestData({...requestData, issuedBy: requestData.status === 'received' ? user?.result?.name : requestData.issuedBy })
     },[requestData.status])
 
 console.log(requestData)

     useEffect(() => {
       var checkId = user?.result?._id
       if(checkId !== undefined) {
         setRequestData({...requestData, userId: [checkId]})
       } else {
         setRequestData({...requestData, userId: [user?.result?.googleId]})
       }
       
     },[location])
 
 
     const handleSubmitRequest =(e)=> {
         e.preventDefault()
         if(currentId) {
           dispatch(updateRequest(currentId, requestData, openSnackbar))
           .then(() => {
            // handleClose()
            window.location.reload()
          })
         } else {
           dispatch(createRequest(requestData, openSnackbar))
           .then(() => {
            handleClose()
            // window.location.reload()
          })
         }
     }
 
   const today = new Date()
   const clear =() => {
     setCurrentId(null) 
     setRequestData({
      name: '', 
      email: '', 
      phone: '', 
      address: '', 
      userId: '',
      type: '',
      equipment: '',
      issuedBy: '',
      quantity: '',
      reason: '',
      note: '',
      status: 'pending',
      requestDate: new Date(),
      approvedDate: null,
      dueDate: today.getTime() + 14 * 24 * 60 * 60 * 1000,
      returnedDate: null,
     })
   }
     
   
   const handleClose = () => {
     setOpen(false);
     clear()
   };
 
   const inputStyle = {
     display: "block",
     padding: "1.4rem 0.75rem",
     width: "100%",
     fontSize: "0.9rem",
     lineHeight: 1.25,
     color: "#55595c",
     backgroundColor: "#fff",
     backgroundImage: "none",
     backgroundClip: "padding-box",
     borderTop: "0",
     borderRight: "0",
     borderBottom: "1px solid #eee",
     borderLeft: "0",
     borderRadius: "3px",
     transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)"
 }

 const postTypes = {
  display: 'flex',
  alignItems: 'center',
  justifycontent: 'center',
  paddingTop: '15px',
  flexWrap: 'wrap'
}

 
   return (
     <div>
         <form >
       <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
             <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{paddingLeft: '20px', color: 'white'}}>
             {currentId? `Update Record for ${requestData.name}` : `Request for ${requestData.equipment}`}
             </DialogTitle>
             <DialogContent dividers>
 
            {user?.result?.isStoreAdmin && (
               <div style={postTypes}>
               <PostType status="Pending" value="pending" checked={requestData.status === "pending" ? true : false } handleChange={handleChange} />
               <PostType status="Approved" value="approved" checked={requestData.status === "approved" ? true : false } handleChange={handleChange} />
               <PostType status="Received" value="received" checked={requestData.status === "received" ? true : false } handleChange={handleChange} />
               <PostType status="Returned" value="returned" checked={requestData.status === "returned" ? true : false } handleChange={handleChange} />
               <PostType status="Partial" value="partial" checked={requestData.status === "partial" ? true : false } handleChange={handleChange} />
               <PostType status="Overdue" value="overdue" checked={requestData.status === "overdue" ? true : false } handleChange={handleChange} />
             </div>
            )}
 
             <div className="customInputs">
              
               {user?.result?.isStoreAdmin && (
                 <>
                <input 
                 placeholder="Name" 
                 style={inputStyle} 
                 name='name' 
                 type='text'  
                 onChange={(e) => setRequestData({...requestData, name: e.target.value})}
                 value={requestData.name} 
               />
 
                 <input 
                 placeholder="Equipment" 
                 style={inputStyle} 
                 name='equipment' 
                 type='text' 
                 onChange={(e) => setRequestData({...requestData, equipment: e.target.value})}
                 value={requestData.equipment} 
               />

              </>
               )}

                 <input 
                 placeholder="Phone Number" 
                 style={inputStyle} 
                 name='phone' 
                 type='number'  
                 onChange={(e) => setRequestData({...requestData, phone: e.target.value})}
                 value={requestData.phone} 
                 required
               />

                <input 
                 placeholder="Quantity" 
                 style={inputStyle} 
                 name='quantity' 
                 type='number' 
                 onChange={(e) => setRequestData({...requestData, quantity: e.target.value})}
                 value={requestData.quantity} 
               />

              <input 
                 placeholder="Reason" 
                 style={inputStyle} 
                 name='reason' 
                 type='text' 
                 onChange={(e) => setRequestData({...requestData, reason: e.target.value})}
                 value={requestData.reason} 
               />

                {user?.result?.isStoreAdmin && (
                  <input 
                  placeholder="Note" 
                  style={inputStyle} 
                  name='note' 
                  type='text' 
                  onChange={(e) => setRequestData({...requestData, note: e.target.value})}
                  value={requestData.note} 
                />

                )}

               <DatePicker requestData={requestData} setRequestData={setRequestData} />


           </div>
 
             </DialogContent>
             <DialogActions>
             <Button  onClick={handleSubmitRequest}  variant="contained" style={{marginRight: '25px'}} >
             {currentId? 'Save Record' : 'Submit Request'}
             </Button>
             </DialogActions>
       </Dialog>
         </form>
     </div>
   );
 }
 
 export default NewRequest