import React, { useRef } from 'react';
import { Box, Button, Typography, Grid, Card, CardContent, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'; // Make sure to import Link

// Styles using makeStyles (Material-UI v4)
const useStyles = makeStyles((theme) => ({
  heroSection: {
    height: '75vh', // Updated height
    backgroundImage: `url(https://res.cloudinary.com/wabis/image/upload/v1626588177/special/Amurum_2_vkebhw.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    color: 'white', // White text color
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay effect
      zIndex: 1,
    },
    '& > *': {
      zIndex: 2, // Ensure content is above overlay
    },
  },
  heroTextMobile: {
    height: '60vh', // Updated height
    paddingTop: theme.spacing(9), // Extra padding on mobile
  },
  subtitle: {
    fontSize: '12px', // Reduced font size
    maxWidth: '70%', // Limit width for readability
    marginTop: theme.spacing(1),
  },
  jobsSection: {
    padding: theme.spacing(4),
  },
  jobCard: {
    minWidth: 275,
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)', // Light shadow
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 15px 45px rgba(0, 0, 0, 0.2)',
    },
  },
  media: {
    height: 140, // Image height for card
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

// Scroll Button Handler
const scrollToSection = (ref) => {
  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth',
  });
};

// Job Card Component
const JobCard = ({ title, description, imageUrl }) => {
  const classes = useStyles();
  return (
    <Card component={Link} to="jobs/communications-officer" className={classes.jobCard}>
  <CardMedia className={classes.media} image={imageUrl} title={title} />
  <CardContent>
    <Typography variant="h6" component="div">
      {title}
    </Typography>
    <Typography variant="body2" color="textSecondary" >
      {description}
    </Typography>
  </CardContent>
  
</Card>
  );
};

const Apply = () => {
  const classes = useStyles();
  const jobsRef = useRef(null); // Reference to jobs section
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen is mobile

  return (
    <Box>
      {/* Hero Section with Overlay */}
      <Box className={`${classes.heroSection} ${isMobile ? classes.heroTextMobile : ''}`}>
        <Typography variant="h3" gutterBottom align="center" style={{color: '#fff', lineHeight: '40px'}}>
          Join Our Conservation Journey
        </Typography>
        <Typography variant="body" className={classes.subtitle} align="center" style={{color: '#fff', lineHeight: '18px'}}>
          As the leading institution for ornithological research in West Africa, we focus 
          on advancing knowledge of avian ecology, wildlife conservation, and sustainable environmental practices. 
           By working with us, you become part of a vibrant community committed to making meaningful contributions toward preserving Africa’s biodiversity.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={() => scrollToSection(jobsRef)}
        >
          See Available Jobs
        </Button>
      </Box>

      {/* Available Jobs Section */}
      <Box ref={jobsRef} className={classes.jobsSection}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <JobCard
              title="Communications Officer [Closed]"
              description="APLORI is seeking a highly motivated and well qualified candidate to fill the position of a Communications Officer."
              imageUrl="https://res.cloudinary.com/wabis/image/upload/v1729050941/aplori/phone-499991_1280_cwyqx4.jpg"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
  
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
  
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Apply;
