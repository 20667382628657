import { 
  CREATE_REQUEST,
  UPDATE_REQUEST,
  DELETE_REQUEST,
  FETCH_REQUESTS,
  FETCH_REQUEST,
  FETCH_REQUESTS_BY_USER,
  START_LOADING,
  END_LOADING,
  FETCH_REQUESTS_BY_EQUIPMENT
} from '../actions/constants';

const requests = (state = { isLoading: true, requests: [] }, action) => {
  switch (action.type) {
    case START_LOADING:
      return {...state, isLoading: true }
    case END_LOADING:
      return {...state, isLoading: false }

    case FETCH_REQUESTS:
      return {
        ...state,
        requests: action.payload,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
        total: action.payload.total
      };

    case FETCH_REQUESTS_BY_USER:
        return { ...state, requests: action.payload.data };
    
    case FETCH_REQUESTS_BY_EQUIPMENT:
      return { ...state, requests: action.payload.data };

    case FETCH_REQUEST:
      return {...state, request: action.payload }
    case CREATE_REQUEST:
      return {...state, requests: [...state.requests, action.payload]}
    case UPDATE_REQUEST:
      return {...state, requests: state.requests.map((post) => (post._id === action.payload._id ? action.payload : post))}
    case DELETE_REQUEST:
      return {...state, requests: state.requests.filter((post) => post._id !== action.payload)}
    default:
      return state;
  }
};

export default requests
