import React from 'react'
import styles from './NgoFounders.module.css'
import { useHistory } from 'react-router-dom'


const NgoFounders = () => {

    const history = useHistory()
    const openPost =(slug) => history.push(`${slug}`)

    const founders = [
        {name: 'Grace Pam Ph.D.', set: 'APLORI set 2004,', title: 'Founding member and Chairperson', place: 'EDEN Creation Care Initiative, Nigeria.', image: 'https://i.postimg.cc/tTJ6vphS/grace-pam.jpg', link: '/member/grace-alheri-pam', website: 'https://nigeria.arocha.org/', logo: 'https://res.cloudinary.com/wabis/image/upload/v1638561913/special/sponsors/EDEN_htqd9f.png' },
        {name: 'Jacey Abalaka Ph.D.', set: 'APLORI set 2004,', title: 'co-founder', place: 'SCERA Nigeria', image: 'https://i.postimg.cc/90q7Vbgy/jacinta.jpg', link: '', website: 'http://scera-ng.org/', logo: 'https://res.cloudinary.com/wabis/image/upload/v1638561913/special/sponsors/Scera_ccoxkd.png' },
        {name: 'Rose Wisdom Adejoh', set: 'APLORI set 2019,', title: 'Founder & CEO', place: 'Better Earth Foundation, Nigeria.', image: 'https://i.postimg.cc/hGpx0f7Q/rose.jpg', link: '', website: '', logo: 'https://i.postimg.cc/50yJ8mTB/download.png' },
       
    ]

    return (
      <div className={styles.featuresContainer} >
            <div className={styles.items}>
                <ul className={styles.autoGrid}>
                    {
                        founders.map((founder) => (
                            <li className={styles.listItem}>
                                <img onClick={() => founder.link ? openPost(founder.link) : null } className={styles.imgThumbnail} src={founder.image} alt={founder.name} />
                                <h2 style={{cursor: 'pointer', textAlign: 'center'}} onClick={() => founder.link ? openPost(founder.link) : null } >{founder.name}</h2>
                                <p style={{fontSize: '14px', lineHeight: '19px', marginTop: '-14px'}}>{founder.set}</p>
                                <p style={{fontSize: '14px', lineHeight: '19px', marginTop: '-14px'}}>
                                    {founder.title} <strong>{founder.place}</strong>
                                    <div style={{textAlign: 'center'}}>
                                    <a href={founder.website} target="_blank" rel="noreferrer">
                                        <img style={{width: '120px'}} src={founder.logo} alt={founder.place} />
                                    </a>
                                </div>
                                </p>
                            </li>
                        ))
                    }

                </ul>
            </div>


        </div>
    )
}

export default NgoFounders
