import React, {useEffect} from 'react'
import styles from './Home.module.css'
import { useHistory } from 'react-router-dom'

import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'


const Home = () => {


    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
    const browserHistory = createBrowserHistory()
    browserHistory.listen((location, action) => {
      ReactGA.pageview(location.pathname + location.search)
    })
  
    useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])

    

    const history = useHistory()
    const openPost =(slug) => history.push(`${slug}`)

    return (
      <div className={styles.featuresContainer} >
            <div className={styles.paragraph}>
                <h1 style={{paddingBottom: '30px'}}>Data Zone</h1>
                <p> 
                Our datazone includes records of research activities conducted since the inception of the institute. Explore the datasets with interactive and beautiful visualization 
                </p>
            </div>

            <div className={styles.items}>
                <ul className={styles.autoGrid}>
                    
                    <li className={styles.listItem}>
                        <img onClick={() => openPost('/datazone/pointcount')} className={styles.imgThumbnail} src="https://i.postimg.cc/sXGHVjWC/istockphoto-1296506522-170667a.jpg" alt="point count" />
                        <h2 style={{cursor: 'pointer'}} onClick={() => openPost('/datazone/pointcount')}>Point count monitoring data</h2>
                    </li>
                    <li className={styles.listItem}>
                        <img onClick={() => openPost('/datazone/birds')} className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1631874710/species/ysv5jdhti4xtoua7ys3s.jpg" alt="birds of Nigeria" />
                        <h2 style={{cursor: 'pointer'}} onClick={() => openPost('/datazone/birds')}>Birds of Nigeria</h2>
                    </li>

                    <li className={styles.listItem}>
                        <a href="http://aplori.st-andrews.ac.uk/" target="_blank" rel="noreferrer">
                        <img className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1629476857/aplori/rnwdn0b5rx92rbwyeqyi.jpg" alt="topic" />
                        <h2 style={{cursor: 'pointer'}}>Ringing guide database</h2>
                        </a>
                    </li>


                </ul>
            </div>


        </div>
    )
}

export default Home
