import React, { useEffect } from 'react'
import StaticPage from '../components/StaticPage/StaticPage'

import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'

const AboutUs = () => {


      
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
  const browserHistory = createBrowserHistory()
  browserHistory.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search)
  })

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

    const title = "Facilities"
    const image = "https://res.cloudinary.com/wabis/image/upload/v1626904605/special/main_hafwn2.jpg"
    const content = "Our research station is a well-equipped field station for teaching and scientific fieldwork with excellent facilities to ensure a smooth working and conducive environment for research. Some of the facilities include a guest house, aviary, a plant nursery, students’ hostel, library, a lecture theatre and a molecular ecology laboratory. Our well-furnished students’ hostel and guest house serve students, visiting researchers and lecturers as well. The students’ hostel is a big 4-bedroom apartment with a sitting room and an outside porch to allow a good birding experience in the mornings and evenings. The guest house is a 5-bedroom apartment with three sitting rooms to accommodate researchers and guests. Another student hostel exists for students that are on SIWES (Student Industrial Work Experience Scheme) training and other students undertaking research. These buildings are well-equipped with 24-hours power supply and internet accessibility. Our molecular ecology laboratory allows for the proper storage of field samples to avoid contamination while her aviary allows for controlled experiments for behavioural ecologists.We have a well-designed lecture theatre for effective and efficient delivery of lectures to students."

    return (
        <div>
            <StaticPage title={title} content={content} image={image} />
        </div>
    )
}

export default AboutUs
