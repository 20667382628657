import { 
    CREATE_EQUIPMENT,
    UPDATE_EQUIPMENT,
    DELETE_EQUIPMENT,
    FETCH_EQUIPMENTS,
    FETCH_EQUIPMENT,
    FETCH_EQUIPMENTS_BY_USER,
    FETCH_EQUIPMENTS_BY_SEARCH,
    START_LOADING,
    END_LOADING
  } from './constants';
  import * as api from '../api/index.js';
  
  export const getEquipment = (id) => async (dispatch) => {
    try {
      // dispatch({ type: START_LOADING })
      const { data } = await api.fetchEquipment(id);
  
      dispatch({ type: FETCH_EQUIPMENT, payload: data });
      // dispatch({ type: END_LOADING })
  
    } catch (error) {
      console.log(error.response);
    } };
  
  
  export const getEquipments = (page) => async (dispatch) => {
    try {
      // dispatch({ type: START_LOADING });
      const { data: { data, currentPage, numberOfPages, total } } = await api.fetchEquipments(page);
  
      dispatch({ type: FETCH_EQUIPMENTS, payload: { data, currentPage, numberOfPages, total } });
      // dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error.response);
    }
  };
  
  export const getEquipmentsByUser = (searchQuery) => async (dispatch) => {
    try {
      // dispatch({ type: START_LOADING });
      const { data: {data, currentPage, numberOfPages, total  } } = await api.fetchEquipmentsByUser(searchQuery);
  
      dispatch({ type: FETCH_EQUIPMENTS_BY_USER, payload: {data, currentPage, numberOfPages, total } });
      // dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error);
    }
  };


  export const getEquipmentsBySearch = (searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data: { data, currentPage, numberOfPages, total  } } = await api.fetchEquipmentsBySearch(searchQuery);
  
      dispatch({ type: FETCH_EQUIPMENTS_BY_SEARCH, payload: { data, currentPage, numberOfPages, total  } });
      dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error.response);
    }
  };
  
  
  export const createEquipment = (post, openSnackbar) => async (dispatch) => {
    try {
      // dispatch({ type: START_LOADING })
      const { data } = await api.createEquipment(post);
      // history.push(`/posts/${data._id}`)
      // dispatch({ type: HIDE_MESSAGE });
      dispatch({ type: CREATE_EQUIPMENT, payload: data });
      openSnackbar('Equipment added successfully')
      // dispatch({ type: DISPLAY_MESSAGE });
      // dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.response);
    }
  };
  
  export const updateEquipment = (id, post, openSnackbar) => async (dispatch) => {
    try {
      const { data } = await api.updateEquipment(id, post);
  
      dispatch({ type: UPDATE_EQUIPMENT, payload: data });
      openSnackbar('Record updated successfully')
    } catch (error) {
      console.log(error);
    }
  };
  
  
  export const deleteEquipment = (id, openSnackbar) => async (dispatch) => {
    try {
     await api.deleteEquipment(id);
  
      dispatch({ type: DELETE_EQUIPMENT, payload: id });
      openSnackbar('Equipment deleted successfully')
    } catch (error) {
      console.log(error);
    }
  };
  