import axios from 'axios';

// const API = axios.create({ baseURL: 'http://localhost:5000' });
const API = axios.create({ baseURL: 'https://aplori.herokuapp.com' });


API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }

  return req;
});

export const fetchPost = (slug) => API.get(`/posts/${slug}`)
export const fetchPosts = (page) => API.get(`/posts?page=${page}`);
export const fetchNews = (page) => API.get(`/posts/news?page=${page}`);
export const fetchResearch = (page) => API.get(`/posts/research?page=${page}`);
export const fetchPublications = (page) => API.get(`/posts/publications?page=${page}`);
export const fetchProposals = (page) => API.get(`/posts/proposals?page=${page}`);
export const fetchDocuments = (page) => API.get(`/posts/documents?page=${page}`);
export const fetchTheses = (page) => API.get(`/posts/theses?page=${page}`);

export const fetchPostsBySearch = (searchQuery) => API.get(`/posts/search?searchQuery=${searchQuery.search || searchQuery.year || searchQuery.postType || 'none'}`);
export const fetchPostsByUser = (searchQuery) => API.get(`/posts/user?searchQuery=${searchQuery.search}`);
export const createPost = (newPost) => API.post('/posts', newPost);
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);

export const fetchSpecies = (page) => API.get(`/species?page=${page}`);
export const fetchSpecie = (slug) => API.get(`/species/${slug}`)
export const fetchSpeciesBySearch = (searchQuery) => API.get(`/species/search?searchQuery=${searchQuery.search || searchQuery.year || 'none'}`);
export const createSpecies = (newSpecies) => API.post('/species', newSpecies);
export const updateSpecies = (id, updatedSpecies) => API.patch(`/species/${id}`, updatedSpecies);
export const deleteSpecies = (id) => API.delete(`/species/${id}`);

export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const forgot = (formData) => API.post('/user/forgot', formData);
export const reset = (formData) => API.post('/user/reset', formData);

export const fetchProfilesBySearch = (searchQuery) => API.get(`/profiles/search?searchQuery=${searchQuery.search || searchQuery.year || 'none'}`);
export const fetchProfile = (id) => API.get(`/profiles/${id}`)
export const fetchProfiles = () => API.get('/profiles');
export const fetchProfilesByUser = (searchQuery) => API.get(`/profiles/user?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`)
export const createProfile = (newProfile) => API.post('/profiles', newProfile);
export const updateProfile = (id, updatedProfile) => API.patch(`/profiles/${id}`, updatedProfile);
export const deleteProfile = (id) => API.delete(`/profiles/${id}`);

export const fetchRequests = (page) => API.get(`/request?page=${page}`);
export const fetchRequest = (id) => API.get(`/request/${id}`)
export const fetchRequestsBySearch = (searchQuery) => API.get(`/request/search?searchQuery=${searchQuery.search || searchQuery.year || 'none'}`);
export const fetchRequestsByUser = (searchQuery) => API.get(`/request/user?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`)
export const fetchRequestsByEquipment = (searchQuery) => API.get(`/request/equipment?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`)
export const createRequest = (newRequest) => API.post('/request', newRequest);
export const updateRequest = (id, updatedRequest) => API.patch(`/request/${id}`, updatedRequest);
export const deleteRequest= (id) => API.delete(`/request/${id}`);

export const fetchEquipments = (page) => API.get(`/equipment?page=${page}`);
export const fetchEquipment = (id) => API.get(`/equipment/${id}`)
export const fetchEquipmentsBySearch = (searchQuery) => API.get(`/equipment/search?searchQuery=${searchQuery.search || 'none'}`);
export const fetchEquipmentsByUser = (searchQuery) => API.get(`/equipment/user?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`)
export const createEquipment = (newEquipment) => API.post('/equipment', newEquipment);
export const updateEquipment = (id, updatedEquipment) => API.patch(`/equipment/${id}`, updatedEquipment);
export const deleteEquipment= (id) => API.delete(`/equipment/${id}`);


//APLORI DATAZONE API FROM MYSQL (SERVER2)
// const DATA_API = axios.create({ baseURL: 'https://aplori-datazone.herokuapp.com' });
const DATA_API = axios.create({ baseURL: 'https://aplori-datazone.onrender.com' });
// const DATA_API = axios.create({ baseURL: 'http://localhost:3001' });
export const fetchPointCountsBySearch = (searchQuery) => DATA_API.get(`/pointcounts/records?year=${searchQuery.year}&species=${searchQuery.species}`)
export const fetchPointAbundanceBySearch = (searchQuery) => DATA_API.get(`/abundance/records?year=${searchQuery.year}&species=${searchQuery.species}`)
export const fetchArrivalAndDepature = (searchQuery) => DATA_API.get(`/arrival/records?year=${searchQuery.year}&species=${searchQuery.species}`)
// export const fetchPointCountsBySearch = () => DATA_API.get('/pointcounts/records');
