

export const plugins = [
    'advlist autolink lists link image table charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount'
  ]

  export const toolbar = 'undo redo | formatselect | ' +
  'bold italic backcolor | alignleft aligncenter ' +
  'alignright alignjustify | link image | table  bullist numlist outdent indent | ' +
  ' help'


  export const contentType = 'body { font-family:DM Sans,Helvetica,Arial,sans-serif; font-size:14px }'
