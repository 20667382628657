import React from 'react'
import styles from './Hero.module.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import parser from 'html-react-parser'
import { useHistory } from 'react-router-dom'

const Hero = ({ posts }) => {

    const history = useHistory()
 

    return (
        <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false} interval={5000}>
        <div className={styles.bigImage} style={{backgroundImage: 'url("https://res.cloudinary.com/wabis/image/upload/v1626588177/special/Amurum_2_vkebhw.jpg")'}}>
            <div className={styles.overlay}>
                <div className={styles.content}>
                    <div>
                        <h1 >The A.P. Leventis Ornithological Research Institute (APLORI)</h1>
                        <p>Dedicated to capacity building for conservation and research in tropical ecology in West Africa</p>
                    </div>
                </div>
            </div>
        </div>


        <div className={styles.bigImage} style={{backgroundImage: 'url("https://res.cloudinary.com/wabis/image/upload/v1636710062/special/Capacity-Building-cleaned_f09ajt.jpg")'}}>
            <div className={styles.overlay}>
                <div className={styles.content}>
                    <div>
                        <h1 style={{fontSize: '60px', lineHeight: '60px'}}>20 years of Capacity Building</h1>
                        <p style={{width: '70%'}}>For Ornithology and Conservation Science in West Africa</p>
                        <div className={styles.buttonContainer}>
                        <button onClick={() => history.push('/capacity-building')} className={styles.button}>Read our story</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        {/* <div className={styles.bigImage} style={{backgroundImage: 'url("https://res.cloudinary.com/wabis/image/upload/v1637234650/special/C8_FOX_KESTREL_t72kcc.jpg")'}}>
            <div className={styles.overlay}>
                <div className={styles.content}>
                    <div>
                        <h1 >APLORI's species-by-species account of Nigerian Birds</h1>
                        <p style={{width: '70%'}}>Detail description, current status and life history of Nigeriam bird species. Complied by the A.P. Leventis Ornithological Research Institute</p>
                        <div className={styles.buttonContainer}>
                        <button onClick={() => history.push('/birds')} className={styles.button}>Visit Bird Portal</button>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}



        {
            posts.slice(0, 4).map((post) => (
                <div key={post._id} className={styles.bigImage} style={{backgroundImage: `url(${post.fileUrl})`}}>
            <div className={styles.overlay}>
                <div className={styles.content}>
                    {/* <div>
                        <img src="https://i.postimg.cc/W1qGRwy6/aplori-logo-white.png" alt="aplori-logo"/>
                    </div> */}
                    <div>
                        <h1 >{post.title}</h1>
                       
                        <div> {parser(post.content.split(' ').splice(0, 16).join(' '))}</div>
                        <div className={styles.buttonContainer}>
                        <button onClick={() => history.push(`/news/${post.slug}`)} className={styles.button}>Read more</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            ))
        }
        

        

        </Carousel>
    )
}

export default Hero
