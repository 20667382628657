import React from 'react'
import styles from './CitizenScience.module.css'


const CitizenScience = () => {


    const maps = [
        {year: 'Dec 2015', image: 'https://res.cloudinary.com/wabis/image/upload/v1637051922/special/Dec-2015_k40now.png'},
        {year: 'Dec 2016', image: 'https://res.cloudinary.com/wabis/image/upload/v1637051922/special/Dec_2016_xfumvf.png'},
        {year: 'Dec 2017', image: 'https://res.cloudinary.com/wabis/image/upload/v1637051922/special/Dec_2017_fdesl8.png'},
        {year: 'Dec 2018', image: 'https://res.cloudinary.com/wabis/image/upload/v1637051921/special/Dec_2018_xkp6g9.png'},
        {year: 'Dec 2019', image: 'https://res.cloudinary.com/wabis/image/upload/v1637051922/special/Dec_2019_f7hoaw.png'},
        {year: 'Dec 2020', image: 'https://res.cloudinary.com/wabis/image/upload/v1637052103/special/Nov-2020_m4mmyk.png'},
        {year: 'Dec 2021', image: 'https://res.cloudinary.com/wabis/image/upload/v1706861274/aplori/2021_ngehvc.png'},
        {year: 'Dec 2022', image: 'https://res.cloudinary.com/wabis/image/upload/v1706861313/aplori/2022_lwi9qs.png'},
        {year: 'Dec 2023', image: 'https://res.cloudinary.com/wabis/image/upload/v1706861347/aplori/2023_fk90z2.png'},
    ]

    return (
      <div className={styles.featuresContainer} >
            <div className={styles.items}>
                <ul className={styles.autoGrid}>
                    {maps.map((map) => (
                        <li className={styles.listItem} key={map.year}>
                        <img className={styles.imgThumbnail} src={map.image} alt={map.year} />
                        <h2 style={{cursor: 'pointer'}} >{map.year}</h2>
                    </li>

                    ))}
                </ul>
            </div>


        </div>
    )
}

export default CitizenScience
