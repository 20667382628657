 /* eslint-disable */ 
import React, { useState, useEffect  } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid } from '@material-ui/core';
import { species } from './species'
import { migrants } from './migrants'

const SpeciesList = ({ searchQuery, setSearchQuery }) => {

  const [selectYear, setSelectYear] = useState(searchQuery.year)
  const [selectSpecies, setSelectSpecies] = useState(searchQuery.species)

  useEffect(() => {
    setSearchQuery({...searchQuery, year: selectYear.year, species: selectSpecies.species})
  }, [selectYear, selectSpecies])


  const year = [
    {title: '2018', year: 2018},
    {title: '2019', year: 2019},
    {title: '2020', year: 2020},
    {title: '2021', year: 2021},
    {title: '2022', year: 2022},
]


  return (
      <Grid container alignItems="center" justify="center">
        <Grid item  style={{padding: '5px'}} >
            <Autocomplete
                id="combo-box-demo"
                options={year}
                disableClearable
                getOptionLabel={(option) => option.title || ''}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Select year" variant="outlined" />}
                value={selectYear}
                onChange={(event, value) => setSelectYear(value)}
            />
        </Grid>
        <Grid item style={{padding: '5px'}}>
            <Autocomplete
                id="combo-box-demo"
                // options={species}
                options={migrants}
                disableClearable
                getOptionLabel={(option) => option.species || ''}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Choose a species" variant="outlined" />}
                value={selectSpecies}
                onChange={(event, value) => setSelectSpecies(value)}
                key=""
            />
        </Grid>

      </Grid>
      
      
  );
}

export default SpeciesList
