import React from 'react'
// import { Link } from 'react-router-dom'
import styles from './Destination.module.css'
import { Book, CloseBook, Send } from './icons'
// import Spinner from './Spinner/Spinner'

const Destination = ( ) => {

  
    return (
       
        <div className={styles.featuresContainer} >
            <div className={styles.items}>
                <ul className={styles.autoGrid}>


                <li className={styles.listItem} style={{backgroundColor: '#3e6947'}}>
                        <div className={styles.svg}>
                         <Send />
                        </div>
                        {/* <p>{Number(weather.pressure_mb/ 1.333).toFixed(1)}</p> */}
                            <h2 style={{color: 'white', fontSize: '16px', lineHeight: '17px', padding: '0px 20px'}}>APLORI Graduates Destinations</h2>
                    </li>

                    <li className={styles.listItem}  style={{backgroundColor: '#eeffdb'}}>
                        <div className={styles.svg}>
                            <CloseBook />
                        </div>
                        <p >25%</p>
                            <h2 style={{color: '#565656', fontSize: '16px', lineHeight: '22px', padding: '5px 25px'}}>Have completed PhDs</h2>
                    </li>


                    <li className={styles.listItem}  style={{backgroundColor: '#f7ffef'}} >
                        <div className={styles.svg}>
                          <Book />
                        </div>
                        <p>24%</p>
                            <h2 style={{color: 'black', fontSize: '16px', lineHeight: '22px', padding: '5px 25px'}} >At different stages of their PhDs</h2>
                    </li>


                    
                </ul>
            </div>
        </div>
    )
}

export default Destination