export const migrants = [
    {
      species: "Common Whitethroat"
    },
    {
      species: "Pied Flycatcher"
    },
    {
      species: "Willow Warbler"
    },
    {
      species: "Montagu's Harrier"
    },
    {
      species: "Eurasian Wryneck"
    },
    {
      species: "Common Redstart"
    },
    {
      species: "Garden Warbler"
    },
    {
      species: "Whinchat"
    },
    {
      species: "Eurasian Marsh Harrier"
    },
    {
      species: "European Bee-Eater"
    },
    {
      species: "Common Whitethroat"
    },
    {
      species: "Pied Flycatcher"
    },
    {
      species: "Pallid Harrier"
    },
    {
      species: "Willow Warbler"
    },
    {
      species: "Montagu's Harrier"
    },
    {
      species: "Common Nightingale"
    },
    {
      species: "European Stonechat"
    },
    {
      species: "Whinchat"
    },
    {
      species: "Eurasian Wryneck"
    },
    {
      species: "Icterine Warbler"
    },
    {
      species: "Common Whitethroat"
    },
    {
      species: "Pied Flycatcher"
    },
    {
      species: "Willow Warbler"
    },
    {
      species: "Pallid Harrier"
    },
    {
      species: "Pied Flycatcher"
    },
    {
      species: "Willow Warbler"
    },
    {
      species: "Common Whitethroat"
    },
    {
      species: "Pallid Harrier"
    },
    {
      species: "Eurasian Marsh Harrier"
    },
    {
      species: "Common Rock Thrush"
    },
    {
      species: "Melodious Warbler"
    },
    {
      species: "Garden Warbler"
    },
    {
      species: "Pied Flycatcher"
    },
    {
      species: "Common Whitethroat"
    },
    {
      species: "Willow Warbler"
    },
    {
      species: "Garden Warbler"
    }
   ]