import React from 'react'
import { useWindowWidth } from '@react-hook/window-size'
import styles from './CapacityBuilding.module.css'



const Coverage = () => {


    const width = useWindowWidth()
    console.log(width)

    return (
      <div className={styles.featuresContainer} >
            <div className={styles.items}>
                <ul className={styles.autoGrid}>

                    <li className={styles.listItem}>
                        <div>
                            <ul style={{padding: '30px'}}>
                                <h2>20 years of Capacity Building for Ornithology and Conservation Science in West Africa</h2>
                                <li><p> APLORI has two core functions. One is <strong style={{color: '#3e6947'}}>  Capacity Building for conservation in West Africa, </strong> and the second is to <strong style={{color: '#3e6947'}}> facilitate fundamental biological research </strong> based in West Africa </p></li>
                                <li><p>Relatively little ecological field research is carried out in West Africa because of lack of infrastructure and logistical difficulties. APLORI aims to help address these issues <strong style={{color: '#3e6947'}}>  by providing a well-run field station where it is possible to carry out research relatively easily </strong> in a tropical savannah environment.</p></li>
                                
                                <li>
                                    <a  className={styles.button} href="/capacity-building">&nbsp;&nbsp; Read Our Story &nbsp;&nbsp;&nbsp; <i className="fas fa-arrow-right"></i>&nbsp;&nbsp;</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    
                <li className={styles.listItem}>
                        <img  className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1637068807/special/Alumni-cleaned_vzy0y4.jpg" alt="Nibap bird map coverage" />
                </li>

                {width > 1300 && (
                     <li className={styles.listItem}>
                     <img  className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1637068807/special/Alumni-cleaned_vzy0y4.jpg" alt="Nibap bird map coverage" />
                    </li>
                )}


                </ul>
               
            </div>


        </div>
    )
}

export default Coverage
