import React from 'react'
import styles from './PhdStudents.module.css'
import { useHistory } from 'react-router-dom'


const PhdStudents = () => {

    const history = useHistory()
    const openPost =(slug) => history.push(`${slug}`)

    return (
      <div className={styles.featuresContainer} >
            <div className={styles.items}>
                <ul className={styles.autoGrid}>

                    <li className={styles.listItem}>
                        <img onClick={() => openPost('/member/nanchin-winifred-kazeh')} className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1628857759/aplori/b5fir4hztxty9f0kuhu5.jpg" alt="nanchin-winifred-kazeh" />
                        <h2 style={{cursor: 'pointer'}} onClick={() => openPost('/member/nanchin-winifred-kazeh')} >NANCHIN WINIFRED KAZEH (University of Jos)</h2>
                    </li>

                    <li className={styles.listItem}>
                        <img onClick={() => openPost('/member/longji-bako')} className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1629406156/aplori/ipeiumq1lhcrmfeiqvn7.jpg" alt="longji-bako" />
                        <h2 style={{cursor: 'pointer'}} onClick={() => openPost('/member/longji-bako')} >LONGJI BAKO (University of Cyprus)</h2>
                    </li>

                    <li className={styles.listItem}>
                        <img onClick={() => openPost('/member/josiah-ibrahim')} className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1629794161/aplori/ydymfm5kqebnsahbhowg.jpg" alt="josiah-ibrahim" />
                        <h2 style={{cursor: 'pointer'}} onClick={() => openPost('/member/josiah-ibrahim')} >JOSIAH IBRAHIM (University of Jos)</h2>
                    </li>

                    <li className={styles.listItem}>
                        <img onClick={() => openPost('/member/panshak-solomon')} className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1629405198/aplori/tqlgr1qcxascmiezcpo9.jpg" alt="panshak-solomon" />
                        <h2 style={{cursor: 'pointer'}} onClick={() => openPost('/member/panshak-solomon')} >PANSHAK SOLOMON (University of Jos)</h2>
                    </li>

                    <li className={styles.listItem}>
                        <img onClick={() => openPost('/member/israel-adedeji-bolade')} className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1630315617/aplori/qr7scxmnj3y23d4cvjf7.jpg" alt="deji-bolade" />
                        <h2 style={{cursor: 'pointer'}} onClick={() => openPost('/member/israel-adedeji-bolade')} >ISRAEL ADEDEJI BOLADE (University of Jos)</h2>
                    </li>
                    <li className={styles.listItem}>
                        <img onClick={() => openPost('/member/anap-grace-ishaku')} className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1637663532/aplori/krobijao0rd8d3q4hfrk.jpg" alt="grace-anap" />
                        <h2 style={{cursor: 'pointer'}} onClick={() => openPost('/member/anap-grace-ishaku')} >ANAP GRACE ISHAKU (University of Jos)</h2>
                    </li>
                    <li className={styles.listItem}>
                        <img onClick={() => openPost('/member/iniunam-iniunam')} className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1629408945/aplori/k49bonajcofmwuyzzobd.jpg" alt="iniunam-iniunam" />
                        <h2 style={{cursor: 'pointer'}} onClick={() => openPost('/member/iniunam-iniunam')} >INIUNAM INIUNAM (University of Jos)</h2>
                    </li>
                    <li className={styles.listItem}>
                        <img onClick={() => openPost('/member/bello-adamu-danmallam')} className={styles.imgThumbnail} src="https://res.cloudinary.com/wabis/image/upload/v1629329802/aplori/fxnhoszker3hl2lzlgah.jpg" alt="bello-adamu" />
                        <h2 style={{cursor: 'pointer'}} onClick={() => openPost('/member/bello-adamu-danmallam')} >BELLO ADAMU DANMALLAM (University of Jos)</h2>
                    </li>

                </ul>
            </div>


        </div>
    )
}

export default PhdStudents
