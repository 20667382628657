import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styles from './NewsDetails.module.css'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Avatar } from '@material-ui/core'
import moment from 'moment'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../../components/Spinner/Spinner'
import ReactShare from '../../components/ReactShare'

import parser from 'html-react-parser'

import { getPost } from '../../actions/posts'

import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  }));


const PostDetails = () => {

    const history = useHistory()
    const classes = useStyles()
    const { post, isLoading } = useSelector((state) => state.posts)
    const dispatch = useDispatch()
    const { slug } = useParams()
    const openUser =(slug) => history.push(`/member/${slug}`)


    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
    const browserHistory = createBrowserHistory()
    browserHistory.listen((location, action) => {
      ReactGA.pageview(location.pathname + location.search)
    })
  
    useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])
  
    
    useEffect(() => {
        dispatch(getPost(slug))
        // eslint-disable-next-line 
    }, [slug])

    if(!post) return null

    if(isLoading) {
        return <Spinner />
    }

    return (
        <div className={styles.layout}>
            <div className={styles.header}>
            <h3>News <span style={{color: '#0daa8b'}}>|</span> {moment(post.createdAt).format("MMM Do YY")}</h3>
                <h1 style={{padding: '10px'}}>{post.title}</h1>
                
                <div className={styles.author} >
                    <h3 style={{marginRight: '5px'}}>Posted by <span style={{cursor: 'pointer', color: '#0daa8b'}}  onClick={() => openUser(post.userSlug)}>{post.author}</span></h3>
                    <Avatar style={{cursor: 'pointer'}} className={classes.small}  alt={post.author} src={post.avatar} />
                </div>

            </div>
            <div className={styles.body}>
                <img className={styles.featuredImage} src={post.fileUrl ? post.fileUrl : "https://i.postimg.cc/QMR5fZtQ/Amurum.jpg"} alt={post.title}></img>
                <div>{parser(post.content)}</div>
            </div>
            
            <ReactShare url={`https://aplori.org/news/${post.slug}`} title={post.title}/>
        </div>
    )
}

export default PostDetails
