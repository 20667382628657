import React from 'react'
import styles from './Thumbnail.module.css'
import { useHistory, useLocation } from 'react-router-dom'

const Thumbnails = ({ profiles }) => {

    const history = useHistory()
    const location = useLocation()

    const openUser =(slug) => history.push(`/member/${slug}`)

    return (
        <div className={styles.featuresContainer} >

            <div className={styles.items}>
                <ul className={styles.autoGrid}>
                   {
                       profiles?.map((profile) => (
                        <li className={styles.listItem} key={profile?.name} onClick={profile?.userId ? () =>openUser(profile?.slug): null}>
                            <div className={styles.imgContainer}>
                                <img  className={styles.imgThumbnail} src={profile?.profilePicture ? profile?.profilePicture : "https://i.postimg.cc/8zbCw3P7/APLORI-thumbnail.png"} alt="" />
                            </div>
                            <h2>{profile?.title} {profile?.name}</h2>
                            <p className={styles.designation}>{location.pathname === '/people/board-members' && `${profile?.placeOfWork ? `${profile?.designation}, ${profile?.placeOfWork}` : ''}`}</p>
                        </li>
                       ))
                   }
                    
                </ul>

            </div>

        </div>
    )
}

export default Thumbnails
