import React, {useState } from 'react';
import { useDispatch } from 'react-redux'
// import styles from './Clients.module.css'
import moment from 'moment'
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Container from '@material-ui/core/Container'
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';

import { deleteRequest } from '../../actions/request';
// import clients from '../../clients.json'

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),

  },
}));

function TablePaginationActions(props) {

  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};



const useStyles2 = makeStyles(theme => ({
  table: {
    minWidth: 500,
  },

  tablecell: {
    fontSize: '14px'
}
}));


const RequestList = ({ setOpen, setCurrentId, requests }) => {
    
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);

  const user = JSON.parse(localStorage.getItem('profile'))
  //If user is admin, display all the list else display only items borrowed bu the user
  const borrowedItemsByUser = requests.filter((item) => item.userId === user?.result._id)
  const rows = user?.result?.isStoreAdmin ? requests : borrowedItemsByUser
  const [rowsPerPage, setRowsPerPage] = useState(rows.length);

  const dispatch = useDispatch()


  
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleEdit = (selectedRequest) => {
    
    setOpen((prevState) => !prevState)
    setCurrentId(selectedRequest)

  }

  function bgColor(status) {
    return status === 'pending' ? {padding: '5px 10px', borderRadius: '50px', border: 'solid 1px #ffd3a8', backgroundColor: '#fff2d6', cursor: 'pointer'}
         : status === 'approved' ? {padding: '5px 10px', borderRadius: '50px', border: 'solid 1px #b5dbff', backgroundColor: '#eaf5ff', cursor: 'pointer'}
         : status === 'received' ? {padding: '5px 10px', borderRadius: '50px', border: 'solid 1px #8e5dba', backgroundColor: '#e4c6ff', cursor: 'pointer'}
         : status === 'returned' ? {padding: '5px 10px', borderRadius: '50px', border: 'solid 1px #99ffad', backgroundColor: '#f0ffdd', cursor: 'pointer'}
         : status === 'overdue' ? {padding: '5px 10px', borderRadius: '50px', border: 'solid 1px #ed383e', backgroundColor: '#ffaaaa', cursor: 'pointer'}
         : status === 'partial' ? {padding: '5px 10px', borderRadius: '50px', border: 'solid 1px #ed383e', backgroundColor: '#ffce7f', cursor: 'pointer'}
         : null;
}
const tableStyle = { width: 160, fontSize: 12, cursor: 'pointer', borderBottom: 'none',  padding: '8px', textAlign: 'left' }
const headerStyle = { borderBottom: 'none', textAlign: 'left', fontWeight: '700', lineHeight: '14px'}

// className={styles.pageLayout}

  return (
    <div style={{padding: '30px 0px', backgroundColor: 'white', width: '97%', margin: '0 auto', borderRadius: '5px'}}>
    <Container style={{width: '100%'}}>
        <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table} aria-label="custom pagination table">

      <TableHead >
          <TableRow >
            <TableCell style={{...headerStyle, width: '10px'}}>S/N</TableCell>
            <TableCell style={headerStyle}>Name</TableCell>
            {/* <TableCell style={headerStyle}>Phone</TableCell> */}
            <TableCell style={headerStyle}>Equipment</TableCell>
            <TableCell style={headerStyle}>Quantity</TableCell>
            <TableCell style={headerStyle}>Status</TableCell>
            <TableCell style={headerStyle}>Date Requested</TableCell>
            {/* <TableCell style={headerStyle}>Date Approved</TableCell> */}
            <TableCell style={headerStyle}>Issued By</TableCell>
            <TableCell style={headerStyle}>Due Date</TableCell>
            <TableCell style={headerStyle}>Return Date</TableCell>
           {user?.result?.isStoreAdmin && (  <TableCell style={headerStyle}>Delete</TableCell> )}
            
          </TableRow>
        </TableHead>

        <TableBody>
          {(rowsPerPage > 0
            ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row, index) => (
            <TableRow key={row._id} styel={{cursor: 'pointer'}} >
              <TableCell style={{...tableStyle, width: '10px'}}>{index + 1}</TableCell>
              <TableCell  style={tableStyle} scope="row" onClick={user?.result?.isStoreAdmin ? () => handleEdit(row._id) : null }> {row.name}</TableCell>
              {/* <TableCell  style={tableStyle} scope="row" onClick={user?.result?.isStoreAdmin ? () => handleEdit(row._id) : null }> {row.phone}</TableCell> */}
              <TableCell style={tableStyle}  onClick={user?.result?.isStoreAdmin ? () => handleEdit(row._id) : null } >{row.equipment} </TableCell>
              <TableCell style={tableStyle} onClick={user?.result?.isStoreAdmin ? () => handleEdit(row._id) : null } >{row.quantity} </TableCell>
              <TableCell style={tableStyle}  onClick={user?.result?.isStoreAdmin ? () => handleEdit(row._id) : null } ><button style={bgColor(row.status)}>{row.status}</button></TableCell>
              <TableCell style={tableStyle}>{moment(row.requestDate).fromNow()}</TableCell>
              {/* <TableCell style={tableStyle}>{row.approvedDate ? moment(row.approvedDate).format("MMM Do YY") : ''}</TableCell> */}
              <TableCell style={tableStyle}>{row.issuedBy}</TableCell>
              <TableCell style={tableStyle}>{moment(row.dueDate).fromNow()}</TableCell>
              <TableCell style={tableStyle}>{row.returnedDate ? moment(row.returnedDate).format("MMM Do YY") : 'Not set'}</TableCell>
              
             {user?.result?.isStoreAdmin && (
              <TableCell style={{...tableStyle, width: '10px'}}>
                <IconButton onClick={() => dispatch(deleteRequest(row._id))}>
                  <DeleteOutlineRoundedIcon style={{width: '20px', height: '20px'}} />
                </IconButton>
              </TableCell>
             )}
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter >
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={6}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    </Container>
    </div>
  );
}

export default RequestList