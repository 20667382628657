import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import styles from './Proposals.module.css'
// import SearchByYear from './SearchByYear'
import Card from './Card'
import { getProposals } from '../../actions/posts'
import { getProfiles } from '../../actions/profile'
import { getPostsBySearch } from '../../actions/posts';
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'
// import Spinner from './Spinner/Spinner';


const Proposals = () => {

  
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
  const browserHistory = createBrowserHistory()
  browserHistory.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search)
  })

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])



  const dispatch = useDispatch()
  const history = useHistory()
  // const location = useLocation()
  const [search, setSearch] = useState('');
  // const [year, setYear] = useState('');
  let year = ''
  const user = JSON.parse(localStorage.getItem('profile'))


  const { numberOfPages } = useSelector((state) => state.posts);
  const { total } = useSelector((state) => state.posts);
  const { posts } = useSelector((state) => state.posts);
  const { profiles } = useSelector((state) => state.profiles)
  const [pageNumber, setPageNumber] = useState()
  const pages = new Array(numberOfPages).fill(0).map((v, i) => i);
  const proposalPosts = posts.filter((p) => p.postType === 'proposal')

  const [profile, setProfile] = useState(null)

  useEffect(() => {
    for (let i = 0; i < profiles.length; i++) {
      if(profiles[i].email === user?.result?.email) {
        setProfile(profiles[i])
      }
    }
     // eslint-disable-next-line 
  },[profiles])


  console.log(profile)


  useEffect(() => {
      dispatch(getProposals(pageNumber));
      dispatch(getProfiles())
      // eslint-disable-next-line
  }, [pageNumber]);

  const gotoPrevious = () => {
    setPageNumber(Math.max(0, pageNumber - 1));
  };

  const gotoNext = () => {
    setPageNumber(Math.min(numberOfPages - 1, pageNumber + 1));
  };
  
  //MATCH PUBLICATIONS WITH THEIR CONTENTS
  const nameEmailMap = profiles.reduce((acc, {
    name,
    email,
    userId,
    profilePicture,
    slug
  }) => {
    acc[email] = {
      name,
      userId,
      profilePicture,
      slug
    };
    return acc;
  }, {})
  
  const articles = proposalPosts?.map(({
    title,
    content,
    doi,
    year,
    _id,
    author,
    avatar,
    authors,
    userId,
    journal,
    slug,
  }) => ({
    title,
    content,
    _id,
    doi,
    year,
    author,
    avatar,
    userId,
    journal,
    slug,
    authors: authors.map(entry => (nameEmailMap[entry] ? { ...nameEmailMap[entry]
    } : {
      name: entry
    }))
  }));
  

  const searchPost = () => {
    if (search.trim() || year.trim() ) {
      dispatch(getPostsBySearch({ search, year }));
      // history.push(`/posts/search?searchQuery=${search || 'none'}&tags=${tags.join(',')}`);
    } else {
      history.push('/proposals');
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchPost();
    }
  };


      if(!posts) return null

      // if(isLoading) {
      //     return <Spinner />
      // }
  // if(posts.length < 60 ) return <Spinner />

    return (
      <div>
        <div className={styles.coverImg}>
                <img src="https://res.cloudinary.com/wabis/image/upload/v1629139501/special/Journal-Cover-aplori-_hmcu27.png" alt="proposal" />
          </div>
        <div className={styles.layout}>
            <div className={styles.header}>
              <div className={styles.topic}>
              <div className={styles.number}>
                {/* {total < 100 ? <Spinner /> :  */}
                <div>
                  <h3>{total}</h3>
                  <p className={styles.summary}>Submissions this year</p>
                </div>
                
                </div>
                <h1 style={{padding: '20px'}}>APLORI's Annual Research Planning</h1>
                
              </div>
                <p className={styles.summary}>APLORI's research planning</p>
                <div className={styles.searchBar}>
                <input type="text" onKeyDown={handleKeyPress} onChange={(e) => setSearch(e.target.value)} placeholder="Search a topic" />
                <button onClick={searchPost} >Search</button>
                </div>
                {/* <div>
                    <SearchByYear year={year} setYear={setYear} searchPost={searchPost} />
                </div> */}
            </div>

            <div className={styles.cardContainer}>
              {articles.map((pub) => (
                <Card key={pub._id} pub={pub} />
              ))
              }
            </div>
            <div className={styles.pagination}>
              <IconButton onClick={gotoPrevious}>
                <FirstPageIcon />
              </IconButton>
              {pages.map((pageIndex) => (
                <button className={`${styles.pageNum}` } key={pageIndex} onClick={() => setPageNumber(pageIndex)}>
                  {pageIndex + 1}
                </button>
              ))}
              <IconButton onClick={gotoNext}>
                <LastPageIcon />
              </IconButton>
             
            </div>

        </div>
        </div>
    )
}

export default Proposals
