import React from 'react'
import styles from './KeyRoles.module.css'
import { useHistory } from 'react-router-dom'
import { founders } from './people'


const KeyRoles = () => {

    const history = useHistory()
    const openPost =(slug) => history.push(`${slug}`)

    return (
      <div className={styles.featuresContainer} >
            <div className={styles.items}>
                <ul className={styles.autoGrid}>

                  {
                      founders.map((founder) => (
                        <li className={styles.listItem} key={founder.name}>
                        <img onClick={founder.link ? () => openPost(founder.link) : null } className={styles.imgThumbnail} src={founder.image} alt={founder.name} />
                        <h2 style={{cursor: 'pointer'}} onClick={founder.link ? () => openPost(founder.link) : null } >{founder.name}</h2>
                        <p style={{fontSize: '14px', lineHeight: '0px'}}>{founder.set}</p>

                        <p style={{fontSize: '14px', lineHeight: '19px'}}>
                        {founder.title} <strong>{founder.place}</strong>
                        </p>
                        <div style={{textAlign: 'center'}}>
                        <a href={founder.website} target="_blank" rel="noreferrer">
                            <img style={founder.imageIsBig ? {width: '50px'} : {width: '120px'}} src={founder.logo} alt={founder.place} />
                        </a>
                        </div>
                    </li>
                      ))
                  }

                    


                </ul>
            </div>


        </div>
    )
}

export default KeyRoles
